import * as types from "./ActionTypes";
const initialState = {
  ADDNEWLEADSs:[],
  isLoading: false,
  isError: false,
};

//Dashboard Details
export const Reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.ADDNEWLEADS_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    }
    case types.ADDNEWLEADS_SUCCESS: {
      return {
        ...state,
        ADDNEWLEADSs: payload,
        isLoading: false,
        isError: false,
      };
    }
    case types.ADDNEWLEADS_FAILURE: {
      return {
        ...state,
        isLoading: false,
        ADDNEWLEADSs: [],
        isError: true,
      };
    }





    default:
      return state;
  }
};
