import React, { useState } from 'react'
import Login from '../Components/Login/Login';
import { Route, Routes, Navigate  } from 'react-router-dom';
import Home from '../Components/Home/Home.jsx';
import Sidebar from '../Components/Sidebar/Sidebar.js';
import Navbar from '../Components/Sidebar/Navbar.js';
// import Enquiries from '../Components/Enquiries.js';
import Customers from '../Components/Customers.js';
import Finance from '../Components/Finance.js';
import Projects from '../Components/Projects.js';
import Report from '../Components/Reports.js';
import Users from '../Components/Users.js';
// import LoginHistory from '../Components/LoginHistory.js';
import Fresh from '../Components/Fresh.js';
import AddNewLead from '../Components/AddNewLead.js';
import AddNewFresh from '../Components/AddNewFresh.js';
import ProposalShared from '../Components/ProposalShared.js';
import Active from '../Components/Active.js';
import Prospective from '../Components/Prospective.js';
import Pipeline from '../Components/Pipeline.js';
import AddNewProposal from '../Components/AddNewProposal.js';
import AddNewActive from '../Components/AddNewActive.js';
import AddNewProspective from '../Components/AddNewProspective.js';
import AddNewPipeline from '../Components/AddNewPipeline.js';
import AddNewProjects from '../Components/AddNewProjects.js';
import AddNewCustomers from '../Components/AddNewCustomers.js';
import Income from '../Components/Income.js';
import Expense from '../Components/Expense.js';
import AddIncome from '../Components/AddIncome.js';
import AddExpense from '../Components/AddExpense.js';
import Settings from '../Components/Settings/Settings.js';
import Dump from '../Components/Dump.js';
import MyProfile from '../Components/Profile/MyProfile.js';
import Reports from '../Components/Reports.js';
import AddUser from '../Components/AddUser.js';
import EditUser from '../Components/EditUser.js';



const AllRoutes = () => {
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(false);

  const handleSidebarChange = (isExpanded) => {
    setIsSidebarExpanded(isExpanded);
  };
  return (
    <Routes>
      <Route path='/login' element={<><Login /></>} />
      <Route path="*" element={<Navigate to="/login" />} />
      <Route path='navbar' element={<><Sidebar onSidebarChange={handleSidebarChange} /><Navbar isSidebarExpanded={isSidebarExpanded} /></>} />
      <Route path='/dashboard' element={<><Sidebar onSidebarChange={handleSidebarChange} /><Home isSidebarExpanded={isSidebarExpanded} /></>} />
      <Route path='/addnewlead' element={<><Sidebar onSidebarChange={handleSidebarChange} /><AddNewLead isSidebarExpanded={isSidebarExpanded} /></>} />
      <Route path='/fresh' element={<><Sidebar onSidebarChange={handleSidebarChange} /><Fresh isSidebarExpanded={isSidebarExpanded} /></>} />
      <Route path='/proposal-shared' element={<><Sidebar onSidebarChange={handleSidebarChange} /><ProposalShared isSidebarExpanded={isSidebarExpanded} /></>} />
      <Route path='/active' element={<><Sidebar onSidebarChange={handleSidebarChange} /><Active isSidebarExpanded={isSidebarExpanded} /></>} />
      <Route path='/prospective' element={<><Sidebar onSidebarChange={handleSidebarChange} /><Prospective isSidebarExpanded={isSidebarExpanded} /></>} />
      <Route path='/pipeline' element={<><Sidebar onSidebarChange={handleSidebarChange} /><Pipeline isSidebarExpanded={isSidebarExpanded} /></>} />
      <Route path='/dump' element={<><Sidebar onSidebarChange={handleSidebarChange} /><Dump isSidebarExpanded={isSidebarExpanded} /></>} />
      <Route path='/projects' element={<><Sidebar onSidebarChange={handleSidebarChange} /><Projects isSidebarExpanded={isSidebarExpanded} /></>} />
      <Route path='/customers' element={<><Sidebar onSidebarChange={handleSidebarChange} /><Customers isSidebarExpanded={isSidebarExpanded} /></>} />
      <Route path='/finance' element={<><Sidebar onSidebarChange={handleSidebarChange} /><Finance isSidebarExpanded={isSidebarExpanded} /></>} />
      <Route path='/report' element={<><Sidebar onSidebarChange={handleSidebarChange} /><Report isSidebarExpanded={isSidebarExpanded} /></>} />
      <Route path='/users' element={<><Sidebar onSidebarChange={handleSidebarChange} /><Users isSidebarExpanded={isSidebarExpanded} /></>} />
      <Route path='/addnewfresh' element={<><Sidebar onSidebarChange={handleSidebarChange} /><AddNewFresh isSidebarExpanded={isSidebarExpanded} /></>} />
      <Route path='/addnewproposal' element={<><Sidebar onSidebarChange={handleSidebarChange} /><AddNewProposal isSidebarExpanded={isSidebarExpanded} /></>} />
      <Route path='/addnewactive' element={<><Sidebar onSidebarChange={handleSidebarChange} /><AddNewActive isSidebarExpanded={isSidebarExpanded} /></>} />
      <Route path='/addnewprospective' element={<><Sidebar onSidebarChange={handleSidebarChange} /><AddNewProspective isSidebarExpanded={isSidebarExpanded} /></>} />
      <Route path='/addnewpipeline' element={<><Sidebar onSidebarChange={handleSidebarChange} /><AddNewPipeline isSidebarExpanded={isSidebarExpanded} /></>} />
      <Route path='/addnewprojects' element={<><Sidebar onSidebarChange={handleSidebarChange} /><AddNewProjects isSidebarExpanded={isSidebarExpanded} /></>} />
      <Route path='/addcustomer' element={<><Sidebar onSidebarChange={handleSidebarChange} /><AddNewCustomers isSidebarExpanded={isSidebarExpanded} /></>} />
      <Route path='/income' element={<><Sidebar onSidebarChange={handleSidebarChange} /><Income isSidebarExpanded={isSidebarExpanded} /></>} />
      <Route path='/addIncome' element={<><Sidebar onSidebarChange={handleSidebarChange} /><AddIncome isSidebarExpanded={isSidebarExpanded} /></>} />
      <Route path='/expense' element={<><Sidebar onSidebarChange={handleSidebarChange} /><Expense isSidebarExpanded={isSidebarExpanded} /></>} />
      <Route path='/addExpense' element={<><Sidebar onSidebarChange={handleSidebarChange} /><AddExpense isSidebarExpanded={isSidebarExpanded} /></>} />
      <Route path='/reports' element={<><Sidebar onSidebarChange={handleSidebarChange} /><Reports isSidebarExpanded={isSidebarExpanded} /></>} />
      <Route path='/adduser' element={<><Sidebar onSidebarChange={handleSidebarChange} /><AddUser isSidebarExpanded={isSidebarExpanded} /></>} />
     
      <Route path='/settings' element={<><Sidebar onSidebarChange={handleSidebarChange} /><Settings isSidebarExpanded={isSidebarExpanded} /></>} />
      <Route path='/myprofile' element={<><Sidebar onSidebarChange={handleSidebarChange} /><MyProfile isSidebarExpanded={isSidebarExpanded} /></>} />
      <Route path='/editUser' element={<><Sidebar onSidebarChange={handleSidebarChange} /><EditUser isSidebarExpanded={isSidebarExpanded} /></>} />


      {/* <Route path='/loginhistory' element={<><Sidebar onSidebarChange={handleSidebarChange} /><Navbar isSidebarExpanded={isSidebarExpanded} currentPage="LoginHistory" /><LoginHistory isSidebarExpanded={isSidebarExpanded} /></>} /> */}
      
    </Routes>
  ) 
}

export default AllRoutes