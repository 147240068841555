import * as types from "./ActionTypes";
const initialState = {
  ADDNEWLEADSs:[],
  GETALLLEADSs:[],
  LEADSTATUSs:[],
  GETPROPOSALs:[],
  GETACTIVEs:[],
  GETPROSPECTIVEs:[],
  GETPIPELINEs:[],
  GETDUMPs:[],
  GETPROJECTs:[],
  GETCUSTOMERSs:[],
  GETREVIEWs:[],
  EDITLEADSs:[],
  isLoading: false,
  isError: false,
};

//Dashboard Details
export const Reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case types.ADDNEWLEADS_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    }
    case types.ADDNEWLEADS_SUCCESS: {
      return {
        ...state,
        ADDNEWLEADSs: payload,
        isLoading: false,
        isError: false,
      };
    }
    case types.ADDNEWLEADS_FAILURE: {
      return {
        ...state,
        isLoading: false,
        ADDNEWLEADSs: [],
        isError: true,
      };
    }

    //Get All New 
    case types.GETALLLEADS_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    }
    case types.GETALLLEADS_SUCCESS: {
      return {
        ...state,
        GETALLLEADSs: payload,
        isLoading: false,
        isError: false,
      };
    }
    case types.GETALLLEADS_FAILURE: {
      return {
        ...state,
        isLoading: false,
        GETALLLEADSs: [],
        isError: true,
      };
    }

    //Lead Status
    case types.LEADSTATUS_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    }
    case types.LEADSTATUS_SUCCESS: {
      return {
        ...state,
        LEADSTATUSs: payload,
        isLoading: false,
        isError: false,
      };
    }
    case types.LEADSTATUS_FAILURE: {
      return {
        ...state,
        isLoading: false,
        LEADSTATUSs: [],
        isError: true,
      };
    }

    //Get Proposal Shared
    case types.GETPROPOSAL_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    }
    case types.GETPROPOSAL_SUCCESS: {
      return {
        ...state,
        GETPROPOSALs: payload,
        isLoading: false,
        isError: false,
      };
    }
    case types.GETPROPOSAL_FAILURE: {
      return {
        ...state,
        isLoading: false,
        GETPROPOSALs: [],
        isError: true,

      }
    }

    //Get Active Leads
    case types.GETACTIVE_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    }
    case types.GETACTIVE_SUCCESS: {
      return {
        ...state,
        GETACTIVEs: payload,
        isLoading: false,
        isError: false,
      };
    }
    case types.GETACTIVE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        GETACTIVEs: [],
        isError: true,

      }
    }

    //Get Prospective
    case types.GETPROSPECTIVE_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    }
    case types.GETPROSPECTIVE_SUCCESS: {
      return {
        ...state,
        GETPROSPECTIVEs: payload,
        isLoading: false,
        isError: false,
      };
    }
    case types.GETPROSPECTIVE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        GETPROSPECTIVEs: [],
        isError: true,

      }
    }

    //Get Pipeline
    case types.GETPIPELINE_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    }
    case types.GETPIPELINE_SUCCESS: {
      return {
        ...state,
        GETPIPELINEs: payload,
        isLoading: false,
        isError: false,
      };
    }
    case types.GETPIPELINE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        GETPIPELINEs: [],
        isError: true,

      }
    }


    //Get all Dump
    case types.GETDUMP_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    }
    case types.GETDUMP_SUCCESS: {
      return {
        ...state,
        GETDUMPs: payload,
        isLoading: false,
        isError: false,
      };
    }
    case types.GETDUMP_FAILURE: {
      return {
        ...state,
        isLoading: false,
        GETDUMPs: [], 
        isError: true,

      }
    }

    //Get All Project
       case types.GETPROJECT_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    }
    case types.GETPROJECT_SUCCESS: {
      return {
        ...state,
        GETPROJECTs: payload,
        isLoading: false,
        isError: false,
      };
    }
    case types.GETPROJECT_FAILURE: {
      return {
        ...state,
        isLoading: false,
        GETPROJECTs: [], 
        isError: true,

      }
    }

    //Get All Customers
    case types.GETCUSTOMERS_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    }
    case types.GETCUSTOMERS_SUCCESS: {
      return {
        ...state,
        GETCUSTOMERSs: payload,
        isLoading: false,
        isError: false,
      };
    }
    case types.GETCUSTOMERS_FAILURE: {
      return {
        ...state,
        isLoading: false,
        GETCUSTOMERSs: [], 
        isError: true,
  
      }
    }

    //Get All Review(finance)
    case types.GETREVIEW_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    }
    case types.GETREVIEW_SUCCESS: {
      return {
        ...state,
        GETREVIEWs: payload,
        isLoading: false,
        isError: false,
      };
    }
    case types.GETREVIEW_FAILURE: {
      return {
        ...state,
        isLoading: false,
        GETREVIEWs: [], 
        isError: true,
  
      }
    }

    //Edit Fresh page Lead
    case types.EDITLEADS_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    }
    case types.EDITLEADS_SUCCESS: {
      return {
        ...state,
        EDITLEADSs: payload,
        isLoading: false,
        isError: false,
      };
    }
    case types.EDITLEADS_FAILURE: {
      return {
        ...state,
        isLoading: false,
        EDITLEADSs: [], 
        isError: true,
  
      }
    }


    default:
      return state;
  }
};
