import * as types from "./ActionTypes";
import axios from "axios";
import { Reducer } from "../AuthReducer/Reducer";


//AddNewLeads
export const AddNewLeads = (token, formData) => async (dispatch) => {
    dispatch({ type: types.ADDNEWLEADS_REQUEST });
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/api/addnewlead`,
        formData,
        {
          headers: {
            Authorization: `${token}`,
          },
        }
      );
      const data = response.data;
      dispatch({ type: types.ADDNEWLEADS_SUCCESS, payload: data });
      console.log(data);
    } catch (error) {
      console.log(error);
      dispatch({ type: types.ADDNEWLEADS_FAILURE });
    }
  };


  export{};