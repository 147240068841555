import logo from './logo.svg';
import Axios from "axios";
import './App.css';
import { Text } from '@chakra-ui/react';
import AllRoutes from "./Routes/AllRoutes";



function App() {
  return (
    <div className="App">
    
      <AllRoutes/>
    </div>
  );
}

export default App;
