import { Box, Checkbox, Flex, Heading, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";
import React from "react";
import Navbar from "./Sidebar/Navbar";
import { Navigate, useNavigate } from "react-router-dom";

const Pipeline = ({ isSidebarExpanded }) => {
  const width = isSidebarExpanded ? "calc(98vw - 270px)" : "calc(98vw - 110px)";
  const leftMargin = isSidebarExpanded ? "270px" : "110px";
  const navigate = useNavigate();

  const handleOnClick = (e) => {
    e.preventDefault();
    navigate("/addnewpipeline");
  };

  return (
    <Box>
      <Navbar
        isSidebarExpanded={isSidebarExpanded}
        currentPage="Pipeline"
      />
      <Box
        position="absolute"
        top={1}
        left={leftMargin}
        transition="left 0.2s ease, width 0.2s ease"
        width={width}
        height={"auto"}
      >
        <Box
          boxShadow="0 0 10px rgba(128, 128, 128, 0.5)"
          // p={3}
          borderRadius={7}
          position={"relative"}
          top={100}
        >
          <Flex p={3} mb={4}>
            <Heading color={"#151617"} as="h5" fontSize={"18px"} fontWeight={"bold"} fontFamily={"Public Sans, sans-serif"}>1 record found</Heading>
            <Box position={"absolute"} right={10}>
              <Flex
                fontSize={"14px"}
                border={"1px solid red"}
                lineHeight={7}
                borderRadius={8}
                fontFamily={"Public Sans, sans-serif"}
                color={"#D80000"}
              >
                <Text borderRight={"1px solid #D80000"} pl={4} pr={4} cursor={"pointer"} _hover={{bg: "#D8000026",}}>
                  X
                </Text>
                <Text borderRight={"1px solid #D80000"} pl={4} pr={4} cursor={"pointer"} _hover={{bg: "#D8000026",}}>
                  Filter
                </Text>
                <Text borderRight={"1px solid #D80000"} pl={4} pr={4} cursor={"pointer"} _hover={{bg: "#D8000026",}}>
                  Bulk Upload
                </Text>
                <Text pl={4} pr={4} cursor={"pointer"} _hover={{bg: "#D8000026",}} onClick={handleOnClick}>Add New</Text>
              </Flex>
            </Box>
          </Flex>
          <TableContainer>
            <Table>
              <Thead>
                <Tr backgroundColor={"#4b4b4b"}>
                  <Th><Checkbox /></Th>
                  <Th color={"white"}>SL No.</Th>
                  <Th color={"white"}>Date</Th>
                  <Th color={"white"}>ID</Th>
                  <Th color={"white"}>Name</Th>  
                  <Th color={"white"}>Email Address</Th>
                  <Th color={"white"}>Phone Number</Th>
                  <Th color={"white"}>Alternate Contact</Th>
                  <Th color={"white"}>Deal Stage</Th>
                  <Th color={"white"}>WhatsApp Number</Th>
                  <Th color={"white"}>Company Name</Th>
                  <Th color={"white"}>Company Address</Th>
                  <Th color={"white"}>City</Th>
                  <Th color={"white"}>State</Th>
                  <Th color={"white"}>Expected Closure Date</Th>
                  <Th color={"white"}>Country</Th>
                  <Th color={"white"}>Website</Th>
                  <Th color={"white"}>Number of Employees</Th>
                  <Th color={"white"}>Nature of Business</Th>
                  <Th color={"white"}>Company Registration Type</Th>
                  <Th color={"white"}>Standard</Th>
                  <Th color={"white"}>Executive</Th>
                  <Th color={"white"}>Service Type</Th>
                  <Th color={"white"}>Closure Probability</Th>
                  <Th color={"white"}>Closure Value</Th>
                  <Th color={"white"}>Gross Value</Th>
                  <Th color={"white"}>Action</Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td><Checkbox /></Td>
                  <Td>1</Td>
                  <Td>Dec 01,2023</Td>
                  <Td>DL5</Td>
                  <Td>Mr.Jasper</Td>
                  <Td>jasperng@oneempower.com</Td>
                  <Td>+65 9749-4181</Td>
                  <Td>+65 9749-4181</Td>
                  <Td>Active</Td>
                  <Td>+65 9749-4181</Td>
                  <Td>OneEmpower private limited</Td>
                  <Td>Singapore and Vietnam</Td>
                  <Td></Td>
                  <Td></Td>
                  <Td>Jan 27, 2024</Td>
                  <Td>Singapore</Td>
                  <Td>URL</Td>
                  <Td>10</Td>
                  <Td></Td>
                  <Td></Td>
                  <Td>ISO 27001:2022</Td>
                  <Td></Td>
                  <Td>Consultation audit & Certification</Td>
                  <Td>10</Td>
                  <Td>7800</Td>
                  <Td>590062</Td>
                  <Td></Td>

                </Tr>
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Box>
  );
};

export default Pipeline;
