import {
  Box,
  Flex,
  Icon,
  Text,
  Tooltip,
  Checkbox,
  Collapse,
} from "@chakra-ui/react";
import { AiOutlineDashboard } from "react-icons/ai";
import { LiaTasksSolid, LiaProjectDiagramSolid } from "react-icons/lia";
import { TbCheckbox } from "react-icons/tb";
import { IoRocketOutline } from "react-icons/io5";
import { BsBag, BsFileEarmarkText } from "react-icons/bs";
import { PiNewspaperLight, PiUsersLight } from "react-icons/pi";
import { MdOutlineCircle } from "react-icons/md";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { useState } from "react";
import Logo from "../Images/Certvalue-Logo.webp";
import { Link } from "react-router-dom";

const Sidebar = ({ onSidebarChange }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isLocked, setIsLocked] = useState(false);
  const [selectedItem, setSelectedItem] = useState("Dashboard");
  const [isEnquiriesOpen, setIsEnquiriesOpen] = useState(false);
  const [isFinanceOpen, setIsFinanceOpen] = useState(false);

  const navItems = [
    { name: "Dashboard", icon: AiOutlineDashboard, path: "/dashboard" },
    { name: "Add New Lead", icon: LiaTasksSolid, path: "/addnewlead" },
    { name: "Fresh", icon: TbCheckbox, path: "/fresh" },
    { name: "Enquiries", icon: IoRocketOutline, path: "#" },
    { name: "Projects", icon: BsBag, path: "/projects" },
    { name: "Customers", icon: PiNewspaperLight, path: "/customers" },
    { name: "Finance", icon: LiaProjectDiagramSolid, path: "#" }, // Modified
    { name: "Report", icon: BsFileEarmarkText, path: "/report" },
    { name: "Users", icon: PiUsersLight, path: "/users" },
  ];

  const enquirySubItems = [
    {
      name: "Proposal Shared",
      icon: MdOutlineCircle,
      path: "/proposal-shared",
    },
    { name: "Active", icon: MdOutlineCircle, path: "/active" },
    { name: "Prospective", icon: MdOutlineCircle, path: "/prospective" },
    { name: "Pipeline", icon: MdOutlineCircle, path: "/pipeline" },
    { name: "Dump", icon: MdOutlineCircle, path: "/dump" },

  ];

  const financeSubItems = [
    { name: "Income", icon: MdOutlineCircle, path: "/income" },
    { name: "Expense", icon: MdOutlineCircle, path: "/expense" },
  ];

  const handleCheckboxChange = () => {
    setIsLocked(!isLocked);
    onSidebarChange(!isLocked);
  };

  const handleItemClick = (itemName) => {
    if (itemName === "Enquiries") {
      setIsEnquiriesOpen(!isEnquiriesOpen);
    } else if (itemName === "Finance") {
      setIsFinanceOpen(!isFinanceOpen);
    } else {
      setSelectedItem(itemName);
      setIsEnquiriesOpen(false);
      setIsFinanceOpen(false);
    }
  };

  return (
    <Box
      as="nav"
      h="100vh"
      w={isHovered || isLocked ? "250px" : "90px"}
      transition="width 0.2s ease"
      boxShadow="5px 0 10px rgba(0, 0, 0, 0.15)"
      backgroundColor={"white"}
      zIndex={1000}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      position="fixed"
      overflowY="auto" // Enables vertical scrolling when the content exceeds the height
    >
      <Flex
        alignItems="center"
        justifyContent="space-between"
        h="80px"
        padding="0 10px"
      >
        <Box as="img" src={Logo} alt="Logo" height={"25px"} mt={-4} />
  
        {isHovered && (
          <Checkbox
            colorScheme="red"
            size="lg"
            borderRadius="full"
            onChange={handleCheckboxChange}
            isChecked={isLocked}
            ml={3}
            mt={-4}
          />
        )}
      </Flex>
  
      <Flex direction="column">
        {navItems.map((item) => (
          <Tooltip
            label={item.name}
            placement="right"
            isDisabled={isHovered || isLocked}
            key={item.name}
          >
            <Box>
              <Link to={item.path} style={{ textDecoration: "none" }}>
                <Flex
                  align="center"
                  p="4"
                  mb={1}
                  cursor="pointer"
                  padding={2}
                  marginLeft={"14px"}
                  marginRight={"14px"}
                  borderRadius={10}
                  bg={
                    selectedItem === item.name
                      ? "linear-gradient(72.47deg, #D80000 22.16%, #7E0000 76.47%)"
                      : "transparent"
                  }
                  textColor={selectedItem === item.name ? "white" : "black"}
                  _hover={{
                    bg: "linear-gradient(72.47deg, #D80000 22.16%, #7E0000 76.47%)",
                    textColor: "white",
                    ".nav-icon": { color: "white" },
                    ".nav-text": { color: "white" },
                  }}
                  onClick={() => handleItemClick(item.name)}
                >
                  <Icon
                    as={item.icon}
                    boxSize="6"
                    color={selectedItem === item.name ? "white" : "#151617"}
                    className="nav-icon"
                  />
                  <Text
                    ml="4"
                    color={selectedItem === item.name ? "white" : "#151617"}
                    className="nav-text"
                    fontStyle={"Public Sans"}
                    fontSize={"15px"}
                    display={isHovered || isLocked ? "block" : "none"}
                  >
                    {item.name}
                  </Text>
                  {(item.name === "Enquiries" || item.name === "Finance") && (
                    <Icon
                      as={ChevronDownIcon}
                      ml="auto"
                      color={isHovered || isLocked ? "black" : "white"}
                      className="nav-icon"
                      transform={
                        (item.name === "Enquiries" && isEnquiriesOpen) ||
                        (item.name === "Finance" && isFinanceOpen)
                          ? "rotate(180deg)"
                          : "rotate(0)"
                      }
                      transition="transform 0.2s ease"
                    />
                  )}
                </Flex>
              </Link>
  
              {/* Enquiries Collapse */}
              {item.name === "Enquiries" && (
                <Collapse
                  in={isEnquiriesOpen && (isHovered || isLocked)}
                  animateOpacity
                >
                  <Flex direction="column" ml={6}>
                    {enquirySubItems.map((subItem) => (
                      <Link
                        to={subItem.path}
                        style={{ textDecoration: "none" }}
                        key={subItem.name}
                      >
                        <Flex
                          align="center"
                          p="2"
                          mb={1}
                          cursor="pointer"
                          padding={2}
                          borderRadius={5}
                          bg={
                            selectedItem === subItem.name
                              ? "linear-gradient(72.47deg, #D80000 22.16%, #7E0000 76.47%)"
                              : "transparent"
                          }
                          textColor={
                            selectedItem === subItem.name ? "white" : "black"
                          }
                          _hover={{
                            bg: "linear-gradient(72.47deg, #D80000 22.16%, #7E0000 76.47%)",
                            textColor: "white",
                            ".nav-icon": { color: "white" },
                            ".nav-text": { color: "white" },
                          }}
                          onClick={() => handleItemClick(subItem.name)}
                        >
                          <Icon
                            as={MdOutlineCircle}
                            boxSize="4"
                            color="gray.600"
                            className="nav-icon"
                          />
                          <Text
                            ml="4"
                            fontSize={"15px"}
                            fontStyle={"Public Sans"}
                            color="black"
                            className="nav-text"
                          >
                            {subItem.name}
                          </Text>
                        </Flex>
                      </Link>
                    ))}
                  </Flex>
                </Collapse>
              )}
  
              {/* Finance Collapse */}
              {item.name === "Finance" && (
                <Collapse
                  in={isFinanceOpen && (isHovered || isLocked)}
                  animateOpacity
                >
                  <Flex direction="column" ml={6}>
                    {financeSubItems.map((subItem) => (
                      <Link
                        to={subItem.path}
                        style={{ textDecoration: "none" }}
                        key={subItem.name}
                      >
                        <Flex
                          align="center"
                          p="2"
                          mb={1}
                          cursor="pointer"
                          padding={2}
                          borderRadius={5}
                          bg={
                            selectedItem === subItem.name
                              ? "linear-gradient(72.47deg, #D80000 22.16%, #7E0000 76.47%)"
                              : "transparent"
                          }
                          textColor={
                            selectedItem === subItem.name ? "white" : "black"
                          }
                          _hover={{
                            bg: "linear-gradient(72.47deg, #D80000 22.16%, #7E0000 76.47%)",
                            textColor: "white",
                            ".nav-icon": { color: "white" },
                            ".nav-text": { color: "white" },
                          }}
                          onClick={() => handleItemClick(subItem.name)}
                        >
                          <Icon
                            as={MdOutlineCircle}
                            boxSize="4"
                            color="gray.600"
                            className="nav-icon"
                          />
                          <Text
                            ml="4"
                            fontSize={"15px"}
                            fontStyle={"Public Sans"}
                            color="black"
                            className="nav-text"
                          >
                            {subItem.name}
                          </Text>
                        </Flex>
                      </Link>
                    ))}
                  </Flex>
                </Collapse>
              )}
            </Box>
          </Tooltip>
        ))}
      </Flex>
    </Box>
  );
  
};

export default Sidebar;
