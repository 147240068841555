import { Box, Flex, Heading, Link, Text } from "@chakra-ui/react";
import React from "react";
import Navbar from "./Sidebar/Navbar";

const Reports = ({ isSidebarExpanded }) => {
  const width = isSidebarExpanded ? "calc(98vw - 270px)" : "calc(98vw - 110px)";
  const leftMargin = isSidebarExpanded ? "270px" : "110px";
  return (
    <>
      <Box>
        <Navbar isSidebarExpanded={isSidebarExpanded} currentPage="Reports" />
        <Box
          position="absolute"
          top={1}
          left={leftMargin}
          transition="left 0.2s ease, width 0.2s ease"
          width={width}
          height={"auto"}
        >
          <Box
            boxShadow="0 0 10px rgba(128, 128, 128, 0.5)"
            // p={3}
            borderRadius={7}
            position={"relative"}
            top={100}
            padding="25px 15px 15px 15px"
          >
            <Box>
              <Heading
                fontSize={"22px"}
                fontWeight={600}
                color={"#5D595C"}
                textAlign={"left"}
              >
                Active Reports
              </Heading>
              <Box
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
                lineHeight={"15px"}
                mt={5}
                borderBottom={"1px solid #dbdade"}
                pb={6}
                color={"#7367f0"}
              >
                <Link
                  pr={4}
                  borderRight={"1px solid black"}
                  textAlign={"left"}
                  textDecoration="none"
                  _hover={{ textDecoration: "none" }}
                >
                  Overall Report
                </Link>
                <Link
                  pr={4}
                  pl={4}
                  borderRight={"1px solid black"}
                  textAlign={"left"}
                  textDecoration="none"
                  _hover={{ textDecoration: "none" }}
                >
                  Update Report
                </Link>
                <Link
                  pr={4}
                  pl={4}
                  borderRight={"1px solid black"}
                  textAlign={"left"}
                  textDecoration="none"
                  _hover={{ textDecoration: "none" }}
                >
                  Detailed Update Report
                </Link>
                <Link
                  pr={4}
                  pl={4}
                  borderRight={"1px solid black"}
                  textAlign={"left"}
                  textDecoration="none"
                  _hover={{ textDecoration: "none" }}
                >
                  By Stage
                </Link>
                <Link
                  pr={4}
                  pl={4}
                  borderRight={"1px solid black"}
                  textAlign={"left"}
                  textDecoration="none"
                  _hover={{ textDecoration: "none" }}
                >
                  By Status
                </Link>
                <Link
                  pr={4}
                  pl={4}
                  textAlign={"left"}
                  textDecoration="none"
                  _hover={{ textDecoration: "none" }}
                >
                  Notes Report
                </Link>
              </Box>
            </Box>
            <Box mt={6}>
              <Heading
                fontSize={"22px"}
                color={"#5D595C"}
                textAlign={"left"}
                fontWeight={600}
              >
                Deal Reports
              </Heading>
              <Box
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
                lineHeight={"15px"}
                mt={5}
                borderBottom={"1px solid #dbdade"}
                pb={6}
                color={"#7367f0"}
              >
                <Link
                  pr={4}
                  borderRight={"1px solid black"}
                  textAlign={"left"}
                  textDecoration="none"
                  _hover={{ textDecoration: "none" }}
                >
                  Overall Report
                </Link>
                <Link
                  pr={4}
                  pl={4}
                  borderRight={"1px solid black"}
                  textAlign={"left"}
                  textDecoration="none"
                  _hover={{ textDecoration: "none" }}
                >
                  Update Report
                </Link>

                <Link
                  pr={4}
                  pl={4}
                  borderRight={"1px solid black"}
                  textAlign={"left"}
                  textDecoration="none"
                  _hover={{ textDecoration: "none" }}
                >
                  By Stage
                </Link>
                <Link
                  pr={4}
                  pl={4}
                  borderRight={"1px solid black"}
                  textAlign={"left"}
                  textDecoration="none"
                  _hover={{ textDecoration: "none" }}
                >
                  By Status
                </Link>
              </Box>
            </Box>
            <Box mt={6}>
              <Heading
                fontSize={"22px"}
                fontWeight={600}
                color={"#5D595C"}
                textAlign={"left"}
              >
                Client Reports
              </Heading>
              <Box
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
                lineHeight={"15px"}
                mt={5}
                borderBottom={"1px solid #dbdade"}
                pb={6}
                color={"#7367f0"}
              >
                <Link
                  pr={4}
                  borderRight={"1px solid black"}
                  textAlign={"left"}
                  textDecoration="none"
                  _hover={{ textDecoration: "none" }}
                >
                  Overall Report
                </Link>
                <Link
                  pr={4}
                  pl={4}
                  borderRight={"1px solid black"}
                  textAlign={"left"}
                  textDecoration="none"
                  _hover={{ textDecoration: "none" }}
                >
                  Update Report
                </Link>
              </Box>
            </Box>
            <Box mt={6}>
              <Heading
                fontSize={"22px"}
                fontWeight={600}
                color={"#5D595C"}
                textAlign={"left"}
              >
                Fresh Reports
              </Heading>
              <Box
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
                lineHeight={"15px"}
                mt={5}
                borderBottom={"1px solid #dbdade"}
                pb={6}
                color={"#7367f0"}
              >
                <Link
                  pr={4}
                  borderRight={"1px solid black"}
                  textAlign={"left"}
                  textDecoration="none"
                  _hover={{ textDecoration: "none" }}
                >
                  Overall Report
                </Link>
                <Link
                  pr={4}
                  pl={4}
                  borderRight={"1px solid black"}
                  textAlign={"left"}
                  textDecoration="none"
                  _hover={{ textDecoration: "none" }}
                >
                  Update Report
                </Link>
              </Box>
            </Box>
            <Box mt={6}>
              <Heading
                fontSize={"22px"}
                fontWeight={600}
                color={"#5D595C"}
                textAlign={"left"}
              >
                Communication Reports
              </Heading>
              <Box
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
                lineHeight={"15px"}
                mt={5}
                borderBottom={"1px solid #dbdade"}
                pb={6}
                color={"#7367f0"}
              >
                <Link
                  pr={4}
                  borderRight={"1px solid black"}
                  textAlign={"left"}
                  textDecoration="none"
                  _hover={{ textDecoration: "none" }}
                >
                  Email Log Report
                </Link>
                <Link
                  pr={4}
                  pl={4}
                  borderRight={"1px solid black"}
                  textAlign={"left"}
                  textDecoration="none"
                  _hover={{ textDecoration: "none" }}
                >
                  SMS Log Report
                </Link>
                <Link
                  pr={4}
                  pl={4}
                  borderRight={"1px solid black"}
                  textAlign={"left"}
                  textDecoration="none"
                  _hover={{ textDecoration: "none" }}
                >
                  Call Log Report
                </Link>
              </Box>
            </Box>
            <Box mt={6}>
              <Box
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
                lineHeight={"15px"}
                mt={5}
                borderBottom={"1px solid #dbdade"}
                pb={6}
                color={"#7367f0"}
              >
                <Link
                  pr={4}
                  textAlign={"left"}
                  textDecoration="none"
                  _hover={{ textDecoration: "none" }}
                >
                  Sales Report
                </Link>
              </Box>
            </Box>
            <Box mt={6}>
              <Heading
                fontSize={"22px"}
                fontWeight={600}
                color={"#5D595C"}
                textAlign={"left"}
              >
                User Reports
              </Heading>
              <Box
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
                lineHeight={"15px"}
                mt={5}
                borderBottom={"1px solid #dbdade"}
                pb={6}
                color={"#7367f0"}
              >
                <Link
                  pr={4}
                  borderRight={"1px solid black"}
                  textAlign={"left"}
                  textDecoration="none"
                  _hover={{ textDecoration: "none" }}
                >
                  Activity Report
                </Link>
                <Link
                  pr={4}
                  pl={4}
                  borderRight={"1px solid black"}
                  textAlign={"left"}
                  textDecoration="none"
                  _hover={{ textDecoration: "none" }}
                >
                  Call Log Report
                </Link>
                <Link
                  pr={4}
                  pl={4}
                  borderRight={"1px solid black"}
                  textAlign={"left"}
                  textDecoration="none"
                  _hover={{ textDecoration: "none" }}
                >
                  Time Sheet Report
                </Link>
                <Link
                  pr={4}
                  pl={4}
                  borderRight={"1px solid black"}
                  textAlign={"left"}
                  textDecoration="none"
                  _hover={{ textDecoration: "none" }}
                >
                  Users Report
                </Link>
                <Link
                  pr={4}
                  pl={4}
                  borderRight={"1px solid black"}
                  textAlign={"left"}
                  textDecoration="none"
                  _hover={{ textDecoration: "none" }}
                >
                  Location Tracking
                </Link>
                <Link
                  pr={4}
                  pl={4}
                  borderRight={"1px solid black"}
                  textAlign={"left"}
                  textDecoration="none"
                  _hover={{ textDecoration: "none" }}
                >
                  Time Spent Report
                </Link>
              </Box>
            </Box>
            <Box mt={6}>
              <Heading
                fontSize={"22px"}
                fontWeight={600}
                color={"#5D595C"}
                textAlign={"left"}
              >
                Other Reports
              </Heading>
              <Box
                display="flex"
                justifyContent="flex-start"
                alignItems="center"
                lineHeight={"15px"}
                mt={5}
                borderBottom={"1px solid #dbdade"}
                pb={6}
                color={"#7367f0"}
              >
                <Link
                  pr={4}
                  borderRight={"1px solid black"}
                  textAlign={"left"}
                  textDecoration="none"
                  _hover={{ textDecoration: "none" }}
                >
                  Income Report
                </Link>
                <Link
                  pr={4}
                  pl={4}
                  borderRight={"1px solid black"}
                  textAlign={"left"}
                  textDecoration="none"
                  _hover={{ textDecoration: "none" }}
                >
                  Expense Report
                </Link>
                <Link
                  pr={4}
                  pl={4}
                  borderRight={"1px solid black"}
                  textAlign={"left"}
                  textDecoration="none"
                  _hover={{ textDecoration: "none" }}
                >
                  Products Report
                </Link>
                <Link
                  pr={4}
                  pl={4}
                  borderRight={"1px solid black"}
                  textAlign={"left"}
                  textDecoration="none"
                  _hover={{ textDecoration: "none" }}
                >
                  Purchase Order Overall Report
                </Link>
                <Link
                  pr={4}
                  pl={4}
                  borderRight={"1px solid black"}
                  textAlign={"left"}
                  textDecoration="none"
                  _hover={{ textDecoration: "none" }}
                >
                  Purchase Order Report By Item
                </Link>
                <Link
                  pl={4}
                  textAlign={"left"}
                  color={"#7367f0"}
                  textDecoration="none"
                  _hover={{ textDecoration: "none" }}
                >
                  Project Time Spent Report
                </Link>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Reports;
