import { Box, Checkbox, Flex, Heading, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";
import React from "react";
import Navbar from "./Sidebar/Navbar";
import { Navigate, useNavigate } from "react-router-dom";

const Customers = ({ isSidebarExpanded }) => {
  const width = isSidebarExpanded ? "calc(98vw - 270px)" : "calc(98vw - 110px)";
  const leftMargin = isSidebarExpanded ? "270px" : "110px";
  const navigate = useNavigate();

  const handleOnClick = (e) => {
    e.preventDefault();
    navigate("/addcustomer");
  };

  return (
    <Box>
      <Navbar
        isSidebarExpanded={isSidebarExpanded}
        currentPage="Customers"
      />
      <Box
        position="absolute"
        top={1}
        left={leftMargin}
        transition="left 0.2s ease, width 0.2s ease"
        width={width}
        height={"auto"}
      >
        <Box
          boxShadow="0 0 10px rgba(128, 128, 128, 0.5)"
          borderRadius={7}
          position={"relative"}
          top={100}
          
        >
          <Flex p={3} mb={4}>
            <Heading color={"#151617"} as="h5" fontSize={"18px"} fontWeight={"bold"} fontFamily={"Public Sans, sans-serif"}>0 record found</Heading>
            <Box position={"absolute"} right={10}>
              <Flex
                fontSize={"14px"}
                border={"1px solid red"}
                lineHeight={7}
                borderRadius={8}
                fontFamily={"Public Sans, sans-serif"}
                color={"#D80000"}
              >
                <Text borderRight={"1px solid #D80000"} pl={4} pr={4} cursor={"pointer"} _hover={{bg: "#D8000026",}}>
                  X
                </Text>
                <Text borderRight={"1px solid #D80000"} pl={4} pr={4} cursor={"pointer"} _hover={{bg: "#D8000026",}}>
                  Filter
                </Text>
                <Text borderRight={"1px solid #D80000"} pl={4} pr={4} cursor={"pointer"} _hover={{bg: "#D8000026",}}>
                  Bulk Upload
                </Text>
                <Text pl={4} pr={4} cursor={"pointer"} _hover={{bg: "#D8000026",}} onClick={handleOnClick}>Add New</Text>
              </Flex>
            </Box>
          </Flex>
          <TableContainer height={"70vh"}>
            <Table>
              <Thead>
                <Tr backgroundColor={"#4b4b4b"}>
                  <Th><Checkbox /></Th>
                  <Th color={"white"}>SL No.</Th>
                  <Th color={"white"}>Action</Th>
                  <Th color={"white"}>Date</Th>
                  <Th color={"white"}>Name</Th>  
                  <Th color={"white"}>Company</Th>   
                  <Th color={"white"}>Email Address</Th>
                  <Th color={"white"}>Phone Number</Th>
                  <Th color={"white"}>State</Th>
                  <Th color={"white"}>Country</Th>
                  <Th color={"white"}>Stage</Th>
                  <Th color={"white"}>Due Date</Th>
                  <Th color={"white"}>Due In</Th>
                  <Th color={"white"}>Number of Employees</Th>
                  <Th color={"white"}>Website</Th>
                  <Th color={"white"}>Nature of Business</Th>
                  <Th color={"white"}>Company Registration Type</Th>
                  <Th color={"white"}>Standard</Th>
                  <Th color={"white"}>Service Type</Th>
                  <Th color={"white"}>Action</Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td></Td>
                  <Td></Td>
                  <Td></Td>
                  <Td></Td>
                  <Td></Td>
                  <Td></Td>
                  <Td></Td>
                  <Td></Td>
                  <Td></Td>
                  <Td></Td>
                  <Td></Td>
                  <Td></Td>
                  <Td></Td>
                  <Td></Td>
                  <Td></Td>
                  <Td></Td>
                  <Td></Td>
                  <Td></Td>
                  <Td></Td>
                  <Td></Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Box>
  );
};

export default Customers;
