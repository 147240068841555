import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Grid,
  Textarea,
  Button,
  useToast,
} from "@chakra-ui/react";
import React, { useState } from "react";
import Navbar from "./Sidebar/Navbar";
import { AddNewLeads } from "../Redux/AppReducer/Action";
import { useDispatch, useSelector } from "react-redux";

const AddNewLead = ({ isSidebarExpanded }) => {
  const width = isSidebarExpanded ? "calc(98vw - 270px)" : "calc(98vw - 110px)";
  const leftMargin = isSidebarExpanded ? "270px" : "110px";

  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const [formData, setFormData] = useState({
    name: "",
    emailAddress: "",
    phoneNumber: "",
    executive: "",
    alternateContact: "",
    designation: "",
    address: "",
    country: "",
    state: "",
    date: "",
    status: "",
    totalNumberOfEmployees: "",
    services: "",
    companyWebsite: "",
  });

  const toast = useToast();

  // Handle input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle form submission
  const handleSubmit = async () => {
    try {
      const updatedFormData = {
        ...formData,
        services: formData.services.split(",").map((service) => service.trim()), // Converting services string to array
      };

      const response = await dispatch(AddNewLeads(token, updatedFormData));

      if (response.status === 200) {
        toast({
          title: "Lead added successfully.",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        // Clear form data after submission
        setFormData({
          name: "",
          emailAddress: "",
          phoneNumber: "",
          executive: "",
          alternateContact: "",
          designation: "",
          address: "",
          country: "",
          state: "",
          date: "",
          status: "",
          totalNumberOfEmployees: "",
          services: "",
          companyWebsite: "",
        });
      }
    } catch (error) {
      toast({
        title: "Error adding lead.",
        description: error.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Box>
      <Navbar
        isSidebarExpanded={isSidebarExpanded}
        currentPage="Add New Lead"
      />
      <Box
        position="absolute"
        top={1}
        left={leftMargin}
        transition="left 0.2s ease, width 0.2s ease"
        width={width}
        height={"auto"}
      >
        <Box
          boxShadow="0 0 10px rgba(128, 128, 128, 0.5)"
          p={3}
          borderRadius={7}
          position={"relative"}
          top={100}
        >
          <Grid templateColumns="repeat(4, 1fr)" gap={4}>
            <FormControl mb={2}>
              <FormLabel pl={"10px"}>Name</FormLabel>
              <Input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                placeholder="Enter your Name"
                required
              />
            </FormControl>

            <FormControl mb={2}>
              <FormLabel pl={"10px"}>Email Address</FormLabel>
              <Input
                type="email"
                name="emailAddress"
                value={formData.emailAddress}
                onChange={handleInputChange}
                placeholder="Enter your Email"
                required
              />
            </FormControl>

            <FormControl mb={2}>
              <FormLabel pl={"10px"}>Phone Number</FormLabel>
              <Input
                type="tel"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleInputChange}
                placeholder="Enter your Phone Number"
                required
              />
            </FormControl>

            <FormControl mb={2}>
              <FormLabel pl={"10px"}>Executive</FormLabel>
              <Input
                type="text"
                name="executive"
                value={formData.executive}
                onChange={handleInputChange}
                placeholder="Enter the Executive's Name"
              />
            </FormControl>

            <FormControl mb={2}>
              <FormLabel pl={"10px"}>Alternate Contact</FormLabel>
              <Input
                type="tel"
                name="alternateContact"
                value={formData.alternateContact}
                onChange={handleInputChange}
                placeholder="Enter Alternate Contact"
              />
            </FormControl>

            <FormControl mb={2}>
              <FormLabel pl={"10px"}>Designation</FormLabel>
              <Input
                type="text"
                name="designation"
                value={formData.designation}
                onChange={handleInputChange}
                placeholder="Enter the Designation"
              />
            </FormControl>

            <FormControl mb={2}>
              <FormLabel pl={"10px"}>Address</FormLabel>
              <Input
                type="text"
                name="address"
                value={formData.address}
                onChange={handleInputChange}
                placeholder="Enter Address"
                required
              />
            </FormControl>

            <FormControl mb={2}>
              <FormLabel pl={"10px"}>Country</FormLabel>
              <Input
                type="text"
                name="country"
                value={formData.country}
                onChange={handleInputChange}
                placeholder="Enter Country"
                required
              />
            </FormControl>

            <FormControl mb={2}>
              <FormLabel pl={"10px"}>State</FormLabel>
              <Input
                type="text"
                name="state"
                value={formData.state}
                onChange={handleInputChange}
                placeholder="Enter State"
              />
            </FormControl>

            <FormControl mb={2}>
              <FormLabel pl={"10px"}>Date</FormLabel>
              <Input
                type="date"
                name="date"
                value={formData.date}
                onChange={handleInputChange}
              />
            </FormControl>

            <FormControl mb={2}>
              <FormLabel pl={"10px"}>Status</FormLabel>
              <Input
                type="text"
                name="status"
                value={formData.status}
                onChange={handleInputChange}
                placeholder="Enter Status"
              />
            </FormControl>

            <FormControl mb={2}>
              <FormLabel pl={"10px"}>Total no. of Employees</FormLabel>
              <Input
                type="number"
                name="totalNumberOfEmployees"
                value={formData.totalNumberOfEmployees}
                onChange={handleInputChange}
                placeholder="Enter number of Employees"
              />
            </FormControl>

            <FormControl mb={2}>
              <FormLabel pl={"10px"}>Services</FormLabel>
              <Textarea
                name="services"
                value={formData.services}
                onChange={handleInputChange}
                placeholder="Enter the services"
                required
              />
            </FormControl>

            <FormControl mb={2}>
              <FormLabel pl={"10px"}>Company Website</FormLabel>
              <Input
                type="text"
                name="companyWebsite"
                value={formData.companyWebsite}
                onChange={handleInputChange}
                placeholder="Enter company website"
              />
            </FormControl>
          </Grid>

          <Button
            background={"#D80000"}
            color={"white"}
            height={8}
            position="absolute"
            bottom={4}
            right={8}
            onClick={handleSubmit}
          >
            Add Lead
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default AddNewLead;
