import { Box, Checkbox, Flex, Heading, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";
import React from "react";
import Navbar from "./Sidebar/Navbar";
import { Navigate, useNavigate } from "react-router-dom";

const Projects = ({ isSidebarExpanded }) => {
  const width = isSidebarExpanded ? "calc(98vw - 270px)" : "calc(98vw - 110px)";
  const leftMargin = isSidebarExpanded ? "270px" : "110px";
  const navigate = useNavigate();

  const handleOnClick = (e) => {
    e.preventDefault();
    navigate("/addnewprojects");
  };

  return (
    <Box>
      <Navbar
        isSidebarExpanded={isSidebarExpanded}
        currentPage="Projects"
      />
      <Box
        position="absolute"
        top={1}
        left={leftMargin}
        transition="left 0.2s ease, width 0.2s ease"
        width={width}
        height={"auto"}
      >
        <Box
          boxShadow="0 0 10px rgba(128, 128, 128, 0.5)"
          // p={3}
          borderRadius={7}
          position={"relative"}
          top={100}
        >
          <Flex p={3} mb={4}>
            <Heading color={"#151617"} as="h5" fontSize={"18px"} fontWeight={"bold"} fontFamily={"Public Sans, sans-serif"}>1 record found</Heading>
            <Box position={"absolute"} right={10}>
              <Flex
                fontSize={"14px"}
                border={"1px solid red"}
                lineHeight={7}
                borderRadius={8}
                fontFamily={"Public Sans, sans-serif"}
                color={"#D80000"}
              >
                <Text borderRight={"1px solid #D80000"} pl={4} pr={4} cursor={"pointer"} _hover={{bg: "#D8000026",}}>
                  X
                </Text>
                <Text borderRight={"1px solid #D80000"} pl={4} pr={4} cursor={"pointer"} _hover={{bg: "#D8000026",}}>
                  Filter
                </Text>
                <Text borderRight={"1px solid #D80000"} pl={4} pr={4} cursor={"pointer"} _hover={{bg: "#D8000026",}}>
                  Bulk Upload
                </Text>
                <Text pl={4} pr={4} cursor={"pointer"} _hover={{bg: "#D8000026",}} onClick={handleOnClick}>Add New</Text>
              </Flex>
            </Box>
          </Flex>
          <TableContainer>
            <Table height={"65vh"}>
              <Thead>
                <Tr backgroundColor={"#4b4b4b"}>
                  <Th><Checkbox /></Th>
                  <Th color={"white"}>#</Th>
                  <Th color={"white"}>ID</Th>
                  <Th color={"white"}>Action</Th>
                  <Th color={"white"}>Project Name</Th>  
                  <Th color={"white"}>Priority</Th>
                  <Th color={"white"}>Description</Th>
                  <Th color={"white"}>Client</Th>
                  <Th color={"white"}>Sales Executive / consultant</Th>
                  <Th color={"white"}>Planned Project Kick off date</Th>
                  <Th color={"white"}>Actual Project Kick off date</Th>
                  <Th color={"white"}>Planned Project Closure date</Th>
                  <Th color={"white"}>Actual Project Closure date</Th>
                  <Th color={"white"}>Project Team size</Th>
                  <Th color={"white"}>Team members	</Th>
                  <Th color={"white"}>Project Status	</Th>
                  <Th color={"white"}>Certificate Issue date	</Th>
                  <Th color={"white"}>1st Surveillance due date	</Th>
                  <Th color={"white"}>2nd surveillance due date	</Th>
                  <Th color={"white"}>ReCertification due date	</Th>
                  <Th color={"white"}>Closure Value	</Th>
                  <Th color={"white"}>Gross Margin	</Th>
                  <Th color={"white"}>Total Tasks	</Th>
                  <Th color={"white"}>Action</Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td></Td>
                  <Td></Td>
                  <Td></Td>
                  <Td></Td>
                  <Td></Td>
                  <Td></Td>
                  <Td></Td>
                  <Td></Td>
                  <Td></Td>
                  <Td></Td>
                  <Td></Td>
                  <Td></Td>
                  <Td></Td>
                  <Td></Td>
                  <Td></Td>
                  <Td></Td>
                  <Td></Td>
                  <Td></Td>
                  <Td></Td>
                  <Td></Td>
                  <Td></Td>
                  <Td></Td>
                  <Td></Td>
                  <Td></Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Box>
  );
};

export default Projects;
