import {
  Box,
  Button,
  Flex,
  Heading,
  useColorMode,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  Icon,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { TbMoonStars, TbUserCircle, TbSettings } from "react-icons/tb";
import { AiOutlineSun, AiOutlineLogout } from "react-icons/ai";
import { HiOutlineViewGridAdd } from "react-icons/hi";
import { TbUrgent } from "react-icons/tb";
import { IoNotificationsOutline } from "react-icons/io5";
import { MdBusiness } from "react-icons/md";
import { ChevronDownIcon } from "@chakra-ui/icons";
import UserImage from "../Images/UserImage.jpg";
import { useNavigate } from "react-router-dom";

const Navbar = ({ isSidebarExpanded, currentPage }) => {
  const width = isSidebarExpanded ? "calc(98vw - 270px)" : "calc(98vw - 110px)";
  const leftMargin = isSidebarExpanded ? "270px" : "110px";
  const [isToggled, setIsToggled] = useState(false);
  const { colorMode, toggleColorMode } = useColorMode();
  const navigate = useNavigate();

  const handleToggle = () => {
    setIsToggled(!isToggled);
    toggleColorMode();
  };

  const handleSettings = (e) => {
    e.preventDefault();
    navigate("/settings");
  }
  const handleProfile = (e) => {
    e.preventDefault();
    navigate("/myprofile");
  }

  return (
    <Box
      position="fixed"
      top={4}
      left={leftMargin}
      transition="left 0.2s ease, width 0.2s ease"
      width={width}
      height={"60px"}
      boxShadow="0 0 10px rgba(128, 128, 128, 0.5)"
      p={3}
      borderRadius={7}
      backgroundColor={"white"}
      zIndex={100}
    >
      <Flex>
        <Box mr={3}>
          <Heading
            as="h2"
            fontSize={"22px"}
            fontFamily={"Public Sans, sans-serif"}
            textAlign={"left"}
            position="absolute"
            top="1.20rem"
          >
            {currentPage}
          </Heading>
        </Box>
        <Box>
          <Flex>
            <Box w={"10%"} mr={6}>
              <Flex direction="row" justify={"space-between"}>
                <Box
                  className="toggle-button-container"
                  position="absolute"
                  top="0.9rem"
                  right="15.5rem"
                >
                  <Button
                    onClick={handleToggle}
                    variant="unstyled"
                    className={`toggle-button ${
                      colorMode === "light" ? "dark" : "light"
                    }`}
                  >
                    {colorMode === "light" ? (
                      <TbMoonStars size={25} />
                    ) : (
                      <AiOutlineSun size={25} />
                    )}
                  </Button>
                </Box>
                <Box position="absolute" top="1.40rem" right="9rem">
                  <Flex gap={3}>
                    <HiOutlineViewGridAdd color="black" size={25} />
                    <TbUrgent color="black" size={25} />
                    <IoNotificationsOutline color="black" size={25} />
                  </Flex>
                </Box>
              </Flex>
            </Box>
            <Box position="absolute" right="2rem">
              <Menu>
                <MenuButton as={Button} variant="unstyled">
                  <img
                    vertical-align={"middle"}
                    width={"40px"}
                    height={"40px"}
                    border-radius={"50px"}
                    object-fit={"cover"}
                    className="image-profile"
                    src={UserImage}
                    alt="hr-logo"
                  />
                </MenuButton>
                <MenuList>
                  <MenuItem color={"#5D596c"} _hover={{bg: "#D8000026", color: "#7367f0"}} icon={<TbUserCircle size={20} />} onClick={handleProfile}>
                    My Profile
                  </MenuItem>
                  <MenuItem color={"#5D596c"} _hover={{bg: "#D8000026", color: "#7367f0"}} icon={<MdBusiness size={20} />}>
                    Company Profile
                  </MenuItem>
                  <MenuItem color={"#5D596c"} _hover={{bg: "#D8000026", color: "#7367f0"}} icon={<TbSettings size={20} />} onClick={handleSettings}>Settings</MenuItem>
                  <MenuDivider />
                  <MenuItem color={"#5D596c"} _hover={{bg: "#D8000026", color: "#7367f0"}} icon={<AiOutlineLogout size={20} />}>Logout</MenuItem>
                </MenuList>
              </Menu>
            </Box>
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
};

export default Navbar;
