import {
  Box,
  Button,
  Checkbox,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Heading,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Navbar from "./Sidebar/Navbar";
import { Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { GetDump } from "../Redux/AppReducer/Action";
import { EditIcon, ViewIcon } from "@chakra-ui/icons";

const Dump = ({ isSidebarExpanded }) => {
  const width = isSidebarExpanded ? "calc(98vw - 270px)" : "calc(98vw - 110px)";
  const leftMargin = isSidebarExpanded ? "270px" : "110px";
  const [searchInput, setSearchInput] = useState("");
  const [selectedLead, setSelectedLead] = useState(null);
  const [editableLead, setEditableLead] = useState(null);
  const {
    isOpen: isViewOpen,
    onOpen: onViewOpen,
    onClose: onViewClose,
  } = useDisclosure();
  // const {
  //   isOpen: isEditOpen,
  //   onOpen: onEditOpen,
  //   onClose: onEditClose,
  // } = useDisclosure();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const getAllDump = useSelector((state) => state.app.GETDUMPs);
  console.log(getAllDump);

  useEffect(() => {
    dispatch(GetDump(token));
  }, [dispatch, token]);

  //   const handleOnClick = (e) => {
  //     e.preventDefault();
  //     navigate("/addnewpipeline");
  //   };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditableLead((prevLead) => ({
      ...prevLead,
      [name]: value,
    }));
  };

  const handleLeadClick = (lead, isEditing = false) => {
    setSelectedLead(lead);

    onViewOpen();
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    });
  };


  return (
    <Box>
      <Navbar isSidebarExpanded={isSidebarExpanded} currentPage="Dump" />
      <Box
        position="absolute"
        top={1}
        left={leftMargin}
        transition="left 0.2s ease, width 0.2s ease"
        width={width}
        height={"auto"}
      >
        <Box
          boxShadow="0 0 10px rgba(128, 128, 128, 0.5)"
          // p={3}
          borderRadius={7}
          position={"relative"}
          top={100}
        >
          <Flex p={3} mb={4}>
            <Heading
              color={"#151617"}
              as="h5"
              fontSize={"18px"}
              fontWeight={"bold"}
              fontFamily={"Public Sans, sans-serif"}
            >
              {getAllDump?.leads?.length} record
              {getAllDump?.leads?.length !== 1 ? "s" : ""} found
            </Heading>
          </Flex>
          <TableContainer>
            <Table>
              <Thead>
                <Tr backgroundColor={"#4b4b4b"}>
                  <Th>
                    <Checkbox />
                  </Th>
                  <Th color={"white"}>SL No.</Th>
                  <Th></Th>
                  {/* <Th color={"white"}>Date</Th> */}
                  <Th color={"white"}>ID</Th>
                  <Th color={"white"}>Name</Th>
                  <Th color={"white"}>Email Address</Th>
                  <Th color={"white"}>Phone Number</Th>
                  {/* <Th color={"white"}>Alternate Contact</Th> */}
                  <Th color={"white"}>Deal Stage</Th>
                  {/* <Th color={"white"}>WhatsApp Number</Th>
                  <Th color={"white"}>Company Name</Th>
                  <Th color={"white"}>Company Address</Th>
                  <Th color={"white"}>City</Th>
                  <Th color={"white"}>State</Th>
                  <Th color={"white"}>Expected Closure Date</Th>
                  <Th color={"white"}>Country</Th>
                  <Th color={"white"}>Website</Th>
                  <Th color={"white"}>Number of Employees</Th>
                  <Th color={"white"}>Nature of Business</Th>
                  <Th color={"white"}>Company Registration Type</Th>
                  <Th color={"white"}>Standard</Th>
                  <Th color={"white"}>Executive</Th>
                  <Th color={"white"}>Service Type</Th>
                  <Th color={"white"}>Closure Probability</Th>
                  <Th color={"white"}>Closure Value</Th>
                  <Th color={"white"}>Gross Value</Th> */}
                </Tr>
              </Thead>
              <Tbody>
                {getAllDump?.leads?.map((Lead, index) => (
                  <Tr key={index.id}>
                    <Td>
                      <Checkbox />
                    </Td>
                    <Td>{index + 1}</Td>
                    <Td>
                      {/* <EditIcon
                        cursor="pointer"
                        mr={2}
                        onClick={() => handleLeadClick(Lead, true)} // Open in edit mode
                      /> */}
                      <ViewIcon
                        cursor="pointer"
                        onClick={() => handleLeadClick(Lead)} // Open in view mode
                      />
                    </Td>
                    {/* <Td>{Lead.date}</Td> */}
                    <Td>{Lead._id.slice(0, 6)}</Td>
                    <Td>{Lead.name}</Td>
                    <Td>{Lead.emailAddress}</Td>
                    <Td>{Lead.phoneNumber}</Td>
                    {/* <Td>{Lead.alternateContact}</Td> */}
                    {/* <Td>{Lead.status}</Td> */}
                    {/* <Td></Td>
                    <Td></Td>
                    <Td></Td>
                    <Td></Td>
                    <Td>{Lead.state}</Td>
                    <Td></Td>
                    <Td>{Lead.country}</Td>
                    <Td>{Lead.companyWebsite}</Td>
                    <Td>{Lead.totalNumberOfEmployees}</Td>
                    <Td></Td>
                    <Td></Td>
                    <Td></Td>
                    <Td>{Lead.executive}</Td>
                    <Td>{Lead.serviceType}</Td>
                    <Td></Td>
                    <Td></Td>
                    <Td></Td> */}
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
      <Drawer
        isOpen={isViewOpen}
        placement="right"
        onClose={onViewClose}
        size={"lg"}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader color={"red"}>View Details</DrawerHeader>

          <DrawerBody>
            {selectedLead && (
              <Box
                p={4}
                borderWidth="1px"
                borderRadius="md"
                boxShadow="md"
                mb={4}
              >
                <Box ml={10}>
                  <Text mb={2}>
                    <Text as="span" fontWeight="bold">
                      ID:
                    </Text>
                    <Text as="span" ml={2}>
                      {selectedLead._id}
                    </Text>
                  </Text>
                  <Text mb={2}>
                    <Text as="span" fontWeight="bold">
                      Date:
                    </Text>
                    <Text as="span" ml={2}>
                      {formatDate(selectedLead.date)}
                    </Text>
                  </Text>
                  <Text mb={2}>
                    <Text as="span" fontWeight="bold">
                      Name:
                    </Text>
                    <Text as="span" ml={2}>
                      {selectedLead.name}
                    </Text>
                  </Text>
                  <Text mb={2}>
                    <Text as="span" fontWeight="bold">
                      Email Address:
                    </Text>
                    <Text as="span" ml={2}>
                      {selectedLead.emailAddress}
                    </Text>
                  </Text>
                  <Text mb={2}>
                    <Text as="span" fontWeight="bold">
                      Phone Number:{" "}
                    </Text>
                    <Text as="span" ml={2}>
                      {selectedLead.phoneNumber}
                    </Text>
                  </Text>

                  <Text mb={2}>
                    <Text as="span" fontWeight="bold">
                      Alternate Contact:
                    </Text>
                    <Text as="span" ml={2}>
                      {selectedLead.alternateContact}
                    </Text>
                  </Text>
                  <Text mb={2}>
                    <Text as="span" fontWeight="bold">
                      Executive:
                    </Text>
                    <Text as="span" ml={2}>
                      {selectedLead.executive}
                    </Text>
                  </Text>
                  <Text mb={2}>
                    <Text as="span" fontWeight="bold">
                      Deal Stage:
                    </Text>
                    <Text as="span" ml={2}>
                      {selectedLead.dealStage}
                    </Text>
                  </Text>
                  <Text mb={2}>
                    <Text as="span" fontWeight="bold">
                      WhatsApp Number:
                    </Text>
                    <Text as="span" ml={2}>
                      {selectedLead.whatsAppNumber}
                    </Text>
                  </Text>

                  <Text mb={2}>
                    <Text as="span" fontWeight="bold">
                      Company Name:
                    </Text>
                    <Text as="span" ml={2}>
                      {selectedLead.companyName}
                    </Text>
                  </Text>
                  <Text mb={2}>
                    <Text as="span" fontWeight="bold">
                      Company Address:
                    </Text>
                    <Text as="span" ml={2}>
                      {selectedLead.companyAddress}
                    </Text>
                  </Text>
                  <Text mb={2}>
                    <Text as="span" fontWeight="bold">
                      City:
                    </Text>
                    <Text as="span" ml={2}>
                      {selectedLead.city}
                    </Text>
                  </Text>
                  <Text mb={2}>
                    <Text as="span" fontWeight="bold">
                      State:
                    </Text>
                    <Text as="span" ml={2}>
                      {selectedLead.state}
                    </Text>
                  </Text>
                  <Text mb={2}>
                    <Text as="span" fontWeight="bold">
                      Expected Closure Date:
                    </Text>
                    <Text as="span" ml={2}>
                      {formatDate(selectedLead.expectedClosureDate)}
                    </Text>
                  </Text>
                  <Text mb={2}>
                    <Text as="span" fontWeight="bold">
                      Country:
                    </Text>
                    <Text as="span" ml={2}>
                      {selectedLead.country}
                    </Text>
                  </Text>
                  <Text mb={2}>
                    <Text as="span" fontWeight="bold">
                      Website:
                    </Text>
                    <Text as="span" ml={2}>
                      {selectedLead.website}
                    </Text>
                  </Text>
                  <Text mb={2}>
                    <Text as="span" fontWeight="bold">
                      Number of Employees:
                    </Text>
                    <Text as="span" ml={2}>
                      {selectedLead.numberOfEmployees}
                    </Text>
                  </Text>
                  <Text mb={2}>
                    <Text as="span" fontWeight="bold">
                      Nature of Business:
                    </Text>
                    <Text as="span" ml={2}>
                      {selectedLead.natureOfBusiness}
                    </Text>
                  </Text>
                  <Text mb={2}>
                    <Text as="span" fontWeight="bold">
                      Company Registration Type:
                    </Text>
                    <Text as="span" ml={2}>
                      {selectedLead.companyRegistrationType}
                    </Text>
                  </Text>
                  <Text mb={2}>
                    <Text as="span" fontWeight="bold">
                      Standard:
                    </Text>
                    <Text as="span" ml={2}>
                      {selectedLead.standard}
                    </Text>
                  </Text>
                  <Text mb={2}>
                    <Text as="span" fontWeight="bold">
                      Service Type:
                    </Text>
                    <Text as="span" ml={2}>
                      {selectedLead.serviceType}
                    </Text>
                  </Text>
                  <Text mb={2}>
                    <Text as="span" fontWeight="bold">
                      Closure Probability:
                    </Text>
                    <Text as="span" ml={2}>
                      {selectedLead.closureProbability}
                    </Text>
                  </Text>
                  <Text mb={2}>
                    <Text as="span" fontWeight="bold">
                      Closure Value:
                    </Text>
                    <Text as="span" ml={2}>
                      {selectedLead.closureValue}
                    </Text>
                  </Text>
                  <Text mb={2}>
                    <Text as="span" fontWeight="bold">
                      Gross Value:
                    </Text>
                    <Text as="span" ml={2}>
                      {selectedLead.grossValue}
                    </Text>
                  </Text>
                  <Text mb={2}>
                    <Text as="span" fontWeight="bold">
                      Action:
                    </Text>
                    <Text as="span" ml={2}>
                      {selectedLead.action}
                    </Text>
                  </Text>
                </Box>
              </Box>
            )}
          </DrawerBody>
          <DrawerFooter>
            <Button onClick={onViewClose}>Close</Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default Dump;
