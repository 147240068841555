export const ADDNEWLEADS_REQUEST = "ADDNEWLEADS_REQUEST";
export const ADDNEWLEADS_SUCCESS = "ADDNEWLEADS_SUCCESS";
export const ADDNEWLEADS_FAILURE = "ADDNEWLEADS_FAILURE";

export const GETALLLEADS_REQUEST = "GETALLLEADS_REQUEST";
export const GETALLLEADS_SUCCESS = "GETALLLEADS_SUCCESS";
export const GETALLLEADS_FAILURE = "GETALLLEADS_FAILURE";

export const LEADSTATUS_REQUEST = "LEADSTATUS_REQUEST";
export const LEADSTATUS_SUCCESS = "LEADSTATUS_SUCCESS";
export const LEADSTATUS_FAILURE = "LEADSTATUS_FAILURE";

export const GETPROPOSAL_REQUEST = "GETPROPOSAL_REQUEST";
export const GETPROPOSAL_SUCCESS = "GETPROPOSAL_SUCCESS";
export const GETPROPOSAL_FAILURE = "GETPROPOSAL_FAILURE";

export const GETACTIVE_REQUEST = "GETACTIVE_REQUEST";
export const GETACTIVE_SUCCESS = "GETACTIVE_SUCCESS";
export const GETACTIVE_FAILURE = "GETACTIVE_FAILURE";

export const GETPROSPECTIVE_REQUEST = "GETPROSPECTIVE_REQUEST";
export const GETPROSPECTIVE_SUCCESS = "GETPROSPECTIVE_SUCCESS";
export const GETPROSPECTIVE_FAILURE = "GETPROSPECTIVE_FAILURE";

export const GETPIPELINE_REQUEST = "GETPIPELINE_REQUEST";
export const GETPIPELINE_SUCCESS = "GETPIPELINE_SUCCESS";
export const GETPIPELINE_FAILURE = "GETPIPELINE_FAILURE";

export const GETDUMP_REQUEST = "GETDUMP_REQUEST";
export const GETDUMP_SUCCESS = "GETDUMP_SUCCESS";
export const GETDUMP_FAILURE = "GETDUMP_FAILURE";

export const GETPROJECT_REQUEST = "GETPROJECT_REQUEST";
export const GETPROJECT_SUCCESS = "GETPROJECT_SUCCESS";
export const GETPROJECT_FAILURE = "GETPROJECT_FAILURE";

export const GETCUSTOMERS_REQUEST = "GETCUSTOMERS_REQUEST";
export const GETCUSTOMERS_SUCCESS = "GETCUSTOMERS_SUCCESS";
export const GETCUSTOMERS_FAILURE = "GETCUSTOMERS_FAILURE";

export const GETREVIEW_REQUEST = "GETREVIEW_REQUEST";
export const GETREVIEW_SUCCESS = "GETREVIEW_SUCCESS";
export const GETREVIEW_FAILURE = "GETREVIEW_FAILURE";

export const EDITLEADS_REQUEST = "EDITLEADS_REQUEST";
export const EDITLEADS_SUCCESS = "EDITLEADS_SUCCESS";
export const EDITLEADS_FAILURE = "EDITLEADS_FAILURE";