import { Box } from '@chakra-ui/react'
import React from 'react'
import Navbar from '../Sidebar/Navbar'

const Home = ({isSidebarExpanded}) => {
  const width = isSidebarExpanded
    ? "calc(99vw - 270px)"
    : "calc(99vw - 110px)";
  const leftMargin = isSidebarExpanded ? "270px" : "110px";

  return (
    <Box>
      <Navbar isSidebarExpanded={isSidebarExpanded} currentPage="Great Day!." />
      <Box
        position="absolute"
        top={5}
        left={leftMargin}
        transition="left 0.2s ease, width 0.2s ease"
        width={width}
      ></Box>
    </Box>
  )
}

export default Home
