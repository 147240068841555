import {
  Box,
  Button,
  Checkbox,
  Flex,
  Heading,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Input,
  useToast,
  IconButton,
  MenuItem,
  MenuList,
  MenuButton,
  Menu,
  HStack,
  FormLabel,
  Textarea,
  VStack,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Navbar from "./Sidebar/Navbar";
import { useDispatch, useSelector } from "react-redux";
import { GetProject } from "../Redux/AppReducer/Action";
import { CloseIcon } from "@chakra-ui/icons";
import { IoChevronDownOutline, IoClose } from "react-icons/io5";
import { FaCheck } from "react-icons/fa6";
import { IoCloseSharp } from "react-icons/io5";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const Projects = ({ isSidebarExpanded }) => {
  const width = isSidebarExpanded ? "calc(98vw - 270px)" : "calc(98vw - 110px)";
  const leftMargin = isSidebarExpanded ? "270px" : "110px";
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const getAllProject = useSelector((state) => state.app.GETPROJECTs);

  const [selectedProjects, setSelectedProjects] = useState({});
  const [currentProject, setCurrentProject] = useState(null);
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState("");
  const [isEditing, setIsEditing] = useState(null);

  const [selectedAssignee, setSelectedAssignee] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [isTableVisible, setIsTableVisible] = useState(true);

  const toast = useToast();

  useEffect(() => {
    dispatch(GetProject(token));
  }, [dispatch, token]);

  const handleProjectTypeChange = (projectId, e) => {
    const projectType = e.target.value;
    setSelectedProjects((prev) => ({
      ...prev,
      [projectId]: projectType,
    }));
  };

  const handleProjectClick = (project) => {
    setCurrentProject(project);
    setComments(project.comments || []);
    setIsTableVisible(false);
  };

  const handleCloseProjectDetails = () => {
    setCurrentProject(null);
    setIsTableVisible(true);
  };

  const handleAddOrEditComment = () => {
    if (newComment.trim()) {
      if (isEditing !== null) {
        const updatedComments = comments.map((comment, index) =>
          index === isEditing
            ? {
                ...comment,
                text: newComment,
                date: new Date().toLocaleString(),
              }
            : comment
        );
        setComments(updatedComments);
        setIsEditing(null);
      } else {
        const updatedComments = [
          { text: newComment, date: new Date().toLocaleString() }, // Add new comment with date
          ...comments,
        ];
        setComments(updatedComments);
      }
      setNewComment("");
    } else {
      toast({
        title: "Comment cannot be empty.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleEditComment = (index) => {
    setIsEditing(index);
    setNewComment(comments[index].text);
  };

  const handleDeleteComment = (index) => {
    const updatedComments = comments.filter((_, i) => i !== index);
    setComments(updatedComments);
    toast({
      title: "Comment deleted.",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
  };

  const assignee = ["Unassigned", "Name 1", "Name 2", "Name 3", "Name 4"];

  const filteredAssignee = assignee.filter((assignee) =>
    assignee.toLowerCase().includes(searchInput.toLowerCase())
  );

  const handleAssigneeSelect = (assignee) => {
    setSelectedAssignee(assignee);
    setSearchInput("");
  };

  const [isEditingName, setIsEditingName] = useState(false);
  const [projectName, setProjectName] = useState("Project Name");
  const [description, setDescription] = useState("");
  const [isEditingDescription, setIsEditingDescription] = useState(false);

  const handleSaveProjectName = () => {
    setIsEditingName(false);
  };

  const handleAddComment = () => {
    const currentDate = new Date().toLocaleString();
    setComments([{ text: newComment, date: currentDate }, ...comments]);
    setNewComment("");
  };

  return (
    <Box>
      <Navbar isSidebarExpanded={isSidebarExpanded} currentPage="Projects" />
      <Box
        position="absolute"
        top={1}
        left={leftMargin}
        transition="left 0.2s ease, width 0.2s ease"
        width={width}
        height={"auto"}
      >
        {isTableVisible && (
          <Box
            boxShadow="0 0 10px rgba(128, 128, 128, 0.5)"
            borderRadius={7}
            position={"relative"}
            top={100}
          >
            <Flex p={3} mb={4}>
              <Heading
                color={"#151617"}
                as="h5"
                fontSize={"18px"}
                fontWeight={"bold"}
                fontFamily={"Public Sans, sans-serif"}
              >
                {getAllProject?.leads?.length} record(s) found
              </Heading>
            </Flex>

            <TableContainer>
              <Table mt={2}>
                <Thead>
                  <Tr backgroundColor={"#4b4b4b"}>
                    <Th>
                      <Checkbox />
                    </Th>
                    <Th color={"white"}>#</Th>
                    <Th color={"white"}>ID</Th>
                    <Th color={"white"}>Project Name</Th>
                    <Th color={"white"}>Assignee</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {getAllProject?.leads?.map((Leads, index) => (
                    <Tr key={index}>
                      <Td>
                        <Checkbox />
                      </Td>
                      <Td>{index + 1}</Td>
                      <Td
                        onClick={() => handleProjectClick(Leads)}
                        cursor={"pointer"}
                        color={"blue.500"}
                      >
                        {Leads._id}
                      </Td>
                      <Td>{Leads.projectName}</Td>
                      <Td>
                        <Menu>
                          <MenuButton
                            width="250px"
                            as={Button}
                            textAlign="left"
                            color={"#6f6b7d"}
                            background={"none"}
                            _hover={{ bg: "none", border: "none" }}
                            border="none"
                            focusBorderColor="none"
                          >
                            {selectedAssignee ? selectedAssignee : "Unassigned"}
                          </MenuButton>
                          <MenuList maxHeight="200px" overflowY="auto">
                            <Box p={2}>
                              <Input
                                placeholder="Select..."
                                value={searchInput}
                                onChange={(e) => setSearchInput(e.target.value)}
                              />
                            </Box>
                            {filteredAssignee.length > 0 ? (
                              filteredAssignee.map((assignee, index) => (
                                <MenuItem
                                  _hover={{
                                    bg: "#D8000026",
                                    textColor: "#D80000",
                                  }}
                                  key={index}
                                  onClick={() => handleAssigneeSelect(assignee)}
                                >
                                  {assignee}
                                </MenuItem>
                              ))
                            ) : (
                              <MenuItem disabled>No matches found</MenuItem>
                            )}
                          </MenuList>
                        </Menu>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
        )}

        {currentProject && (
          <Box
            boxShadow="0 0 10px rgba(128, 128, 128, 0.5)"
            borderRadius={7}
            position={"relative"}
            top={100}
            p={6}
          >
            <Flex justifyContent="space-between" alignItems="center" mb={4}>
              <Box
                as="h4"
                fontSize={20}
                fontWeight="bold"
                color={"grey"}
                textAlign={"left"}
              >
                Project Details
              </Box>
              <IconButton
                icon={<IoCloseSharp size={20} />}
                onClick={handleCloseProjectDetails}
                aria-label="Close Project Details"
              />
            </Flex>

            <Flex>
              <Box w="70%" h="400px" overflowY="auto" p={6}>
                
                <VStack>
                  <Box w={"full"} mb={6}>
                    {isEditingName ? (
                      <HStack spacing={4}>
                        <Input
                          value={projectName}
                          onChange={(e) => setProjectName(e.target.value)}
                          autoFocus
                        />
                        <IconButton
                          icon={<FaCheck />}
                          onClick={handleSaveProjectName}
                          aria-label="Save"
                        />
                        <IconButton

                          icon={<IoCloseSharp size={20} />}
                          onClick={() => setIsEditingName(false)}
                          aria-label="Cancel"
                        />
                      </HStack>
                    ) : (
                      <Heading size="md" onClick={() => setIsEditingName(true)}>
                        {projectName}
                      </Heading>
                    )}
                  </Box>

                  <Box w="full">
                    <FormLabel as={"h3"} fontWeight={"bold"}>
                      Description :
                    </FormLabel>
                    {isEditingDescription ? (
                      <>
                        <Input
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                          autoFocus
                        />
                        <HStack mt={2} spacing={4}>
                          <Button
                            onClick={() => setIsEditingDescription(false)}
                          >
                            Save
                          </Button>
                          <Button
                            onClick={() => setIsEditingDescription(false)}
                          >
                            Cancel
                          </Button>
                        </HStack>
                      </>
                    ) : (
                      <Box
                        onClick={() => setIsEditingDescription(true)}
                        _hover={{ cursor: "pointer", bg: "#f5f5f5" }}
                        p={3}
                        borderRadius={5}
                        borderWidth={1}
                      >
                        <Text>
                          {description || "Add project description..."}
                        </Text>
                      </Box>
                    )}
                  </Box>

                  <Box w="full">
                  <FormLabel as="h3" fontWeight="bold">
                    Activity :
                  </FormLabel>

                  <Box>
                    <ReactQuill
                      theme="snow"
                      value={newComment}
                      onChange={setNewComment}
                      placeholder="Add a comment..."
                      style={{ minHeight: "120px" }}
                    />
                    <HStack spacing={4} mt={2}>
                      <Button onClick={handleAddOrEditComment}>Save</Button>
                      <Button onClick={() => setNewComment("")}>Cancel</Button>
                    </HStack>
                  </Box>

                  {comments.length > 0 ? (
                    comments.map((comment, index) => (
                      <Box
                        key={index}
                        my={4}
                        p={4}
                        borderWidth={1}
                        borderRadius="md"
                      >
                        {isEditing === index ? (
                          <>
                            <ReactQuill
                              theme="snow"
                              value={newComment}
                              onChange={setNewComment}
                            />
                            <HStack mt={2} spacing={4}>
                              <Button
                                onClick={() => {
                                  handleAddOrEditComment();
                                  setIsEditing(null);
                                }}
                              >
                                Save
                              </Button>
                              <Button onClick={() => setIsEditing(null)}>
                                Cancel
                              </Button>
                            </HStack>
                          </>
                        ) : (
                          <>
                            <Box dangerouslySetInnerHTML={{ __html: comment.text }} mb={2}></Box>
                            <HStack justifyContent="space-between">
                              <Text fontSize="sm" color="gray.500">
                                {comment.date}
                              </Text>
                              <HStack>
                                <Button
                                  variant="link"
                                  onClick={() => handleEditComment(index)}
                                >
                                  Edit
                                </Button>
                                <Button
                                  variant="link"
                                  ml={2}
                                  onClick={() => handleDeleteComment(index)}
                                >
                                  Delete
                                </Button>
                              </HStack>
                            </HStack>
                          </>
                        )}
                      </Box>
                    ))
                  ) : (
                    <Text pl={2}>No comments yet.</Text>
                  )}
                </Box>
              </VStack>
            </Box>
              <Box
                w="30%"
                h="100%"
                p={6}
                borderLeftWidth={1}
              >
                <Heading as="span" flex="1" textAlign="left" fontSize={18}>
                  Details
                </Heading>

                <Text mt={6}>
                  <FormLabel>Assignee:</FormLabel>
                  <Menu>
                    <MenuButton
                      width="250px"
                      as={Button}
                      rightIcon={<IoChevronDownOutline />}
                      textAlign="left"
                      color={"#6f6b7d"}
                      background={"none"}
                      border={"1px solid #dbdade"}
                      _hover={{ bg: "none" }}
                    >
                      {selectedAssignee ? selectedAssignee : "Unassigned"}
                    </MenuButton>
                    <MenuList maxHeight="200px" overflowY="auto">
                      <Box p={2}>
                        <Input
                          placeholder="Select..."
                          value={searchInput}
                          onChange={(e) => setSearchInput(e.target.value)}
                        />
                      </Box>
                      {filteredAssignee.length > 0 ? (
                        filteredAssignee.map((assignee, index) => (
                          <MenuItem
                            _hover={{
                              bg: "#D8000026",
                              textColor: "#D80000",
                            }}
                            key={index}
                            onClick={() => handleAssigneeSelect(assignee)}
                          >
                            {assignee}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem disabled>No matches found</MenuItem>
                      )}
                    </MenuList>
                  </Menu>
                </Text>
                <Flex>
                  <FormLabel mt={3}>Reporter:</FormLabel>
                  <Text> {currentProject.reporter}</Text>
                </Flex>
                <Flex>
                  <FormLabel mt={3}>Created Date:</FormLabel>
                  <Text> {currentProject.createdDate}</Text>
                </Flex>
                <Flex>
                  <FormLabel mt={3}>Created Time:</FormLabel>
                  <Text> {currentProject.createdTime}</Text>
                </Flex>
                
              </Box>
            </Flex>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Projects;
