import {
  Box,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Grid,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import React, { useState } from "react";
import Navbar from "./Sidebar/Navbar";
import { IoChevronDownOutline } from "react-icons/io5";
import Select from "react-select";
import countryList from "react-select-country-list";

const AddNewFresh = ({ isSidebarExpanded }) => {
  const options = countryList().getData();
  const [value, setValue] = React.useState(null);
  const width = isSidebarExpanded ? "calc(98vw - 270px)" : "calc(98vw - 110px)";
  const leftMargin = isSidebarExpanded ? "270px" : "110px";

  const [searchInput, setSearchInput] = useState("");
  const [selectedStage, setSelectedStage] = useState("");
  const [selectedRegType, setSelectedRegType] = useState("");
  const [selectedService, setSelectedService] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedSource, setSelectedSource] = useState("");
  const [selectedExecutive, setSelectedExecutive] = useState("");


  const handleChange = (value) => {
    setValue(value);
  };

  // Stages Dropdown

  const stages = [
    "Active",
    "Prospective",
    "Pipeline",
    "Dump",
    "Invalid",
    "Converted to Deals",
  ];

  const filteredStages = stages.filter((stage) =>
    stage.toLowerCase().includes(searchInput.toLowerCase())
  );

  const handleStageSelect = (stage) => {
    setSelectedStage(stage);
    setSearchInput("");
  };


    // Stages registrationTypes

  const registrationTypes = [
    "Sole Proprietorship/Individual Entrepreneurship",
    "Partnership",
    "Limited Liability Company (LLC)",
    "Corporation",
    "Nonprofit Organization",
    "Cooperative",
    "Joint Venture",
    "Branch Office",
    "Subsidiary Company",
    "State-Owned Enterprise",
    "One-Person Company",
    "Public Company",
    "Family-Owned Business",
    "Offshore Company",
    "Social Enterprise",
    "LLP",
  ];

  const filteredRegTypes = registrationTypes.filter((registrationType) =>
    registrationType.toLowerCase().includes(searchInput.toLowerCase())
  );

  const handleRegType = (registrationType) => {
    setSelectedRegType(registrationType);
    setSearchInput("");
  };

  // Services Dropdowns

  const services = [
    "Audit & Certification",
    "Consultation audit & Certification",
    "Consultation",
    "Training",
  ];

  const filteredServices = services.filter((service) =>
    service.toLowerCase().includes(searchInput.toLowerCase())
  );

  const handleServiceSelect = (service) => {
    setSelectedService(service);
    setSearchInput("");
  };

    // Status Dropdowns

    const status = [
      "Unattended",
      "Active",
      "Spoke",
      "Proposal Sent",
      "Meeting Fixed",
      "Met",
      "Converted to Deal",
      "Converted to Fresh",
      "Lost",
      "Closed",
    ];
  
    const filteredStatus = status.filter((status) =>
      status.toLowerCase().includes(searchInput.toLowerCase())
    );
  
    const handleStatusSelect = (status) => {
      setSelectedStatus(status);
      setSearchInput("");
    };


    // Services Dropdowns

    const sources = [
      "Upsale",
      "Cross sale",
      "Lead Generation",
      "Referral",
    ];
  
    const filteredSource= sources.filter((source) =>
      source.toLowerCase().includes(searchInput.toLowerCase())
    );
  
    const handleSourceSelect = (source) => {
      setSelectedSource(source);
      setSearchInput("");
    };

    // Executive Dropdowns

    const executive = [
      "Name 1",
      "Name 2",
      "Name 3",
      "Name 4",
    ];
  
    const filteredExecutive= executive.filter((executive) =>
      executive.toLowerCase().includes(searchInput.toLowerCase())
    );
  
    const handleExecutiveSelect = (executive) => {
      setSelectedExecutive(executive);
      setSearchInput("");
    };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isFocused ? "#D80000" : "black", 
      backgroundColor: state.isFocused ? "#D8000026" : "white", 
    }),
    // control: (provided) => ({
    //   ...provided,
    //   // borderColor: "#D80000",
    // }),
  };


  return (
    <Box>
      <Navbar
        isSidebarExpanded={isSidebarExpanded}
        currentPage="Add New Fresh"
      />
      <Box
        position="absolute"
        top={1}
        left={leftMargin}
        transition="left 0.2s ease, width 0.2s ease"
        width={width}
      >
        <Box
          boxShadow="0 0 10px rgba(128, 128, 128, 0.5)"
          p={6}
          borderRadius={7}
          position={"relative"}
          top={100}
          height={"83vh"}
        >
          <Grid templateColumns="repeat(4, 1fr)" columnGap={5} rowGap={2}>
            <FormControl mb={2}>
              <FormLabel
                fontSize={"0.9375rem"}
                lineHeight={"1.47"}
                mb={-0.2}
                color={"#6f6b7d"}
              >
                Date:
              </FormLabel>
              <Input type="date" name="Name" required />
            </FormControl>

            <FormControl mb={2}>
              <FormLabel
                fontSize={"0.9375rem"}
                lineHeight={"1.47"}
                mb={-0.2}
                color={"#6f6b7d"}
              >
                Name:
              </FormLabel>
              <Input type="text" name="Name" required />
            </FormControl>

            <FormControl mb={2}>
              <FormLabel
                fontSize={"0.9375rem"}
                lineHeight={"1.47"}
                mb={-0.2}
                color={"#6f6b7d"}
              >
                Email Address:
              </FormLabel>
              <Input type="email" name="Email" required />
            </FormControl>

            <FormControl mb={2}>
              <FormLabel
                fontSize={"0.9375rem"}
                lineHeight={"1.47"}
                mb={-0.2}
                color={"#6f6b7d"}
              >
                Phone Number:
              </FormLabel>
              <Input type="telephone" name="Phone" required />
            </FormControl>

            <FormControl mb={2}>
              <FormLabel
                fontSize={"0.9375rem"}
                lineHeight={"1.47"}
                mb={-0.2}
                color={"#6f6b7d"}
              >
                Alternate Phone Number:
              </FormLabel>
              <Input type="telephone" name="phone number" />
            </FormControl>

            <FormControl mb={2}>
              <FormLabel
                fontSize={"0.9375rem"}
                lineHeight={"1.47"}
                mb={-0.2}
                color={"#6f6b7d"}
              >
                WhatsApp Number:
              </FormLabel>
              <Input type="telephone" name="WhatsApp" />
            </FormControl>

            <FormControl mb={2}>
              <FormLabel
                fontSize={"0.9375rem"}
                lineHeight={"1.47"}
                mb={-0.2}
                color={"#6f6b7d"}
              >
                Company Name:
              </FormLabel>
              <Input type="text" name="Company name" />
            </FormControl>

            <FormControl mb={2}>
              <FormLabel
                fontSize={"0.9375rem"}
                lineHeight={"1.47"}
                mb={-0.2}
                color={"#6f6b7d"}
              >
                Stage:
              </FormLabel>

              <Menu>
                <MenuButton
                  as={Button}
                  rightIcon={<IoChevronDownOutline />}
                  width="100%"
                  textAlign="left"
                  color={"#6f6b7d"}
                  background={"none"}
                  border={"1px solid #dbdade"}
                  _hover={{bg: "none"}}
                >
                  {selectedStage ? selectedStage : "Select Stage"}
                </MenuButton>
                <MenuList maxHeight="200px" overflowY="auto">
                  <Box p={2}>
                    <Input
                      placeholder="Stage..."
                      value={searchInput}
                      onChange={(e) => setSearchInput(e.target.value)}
                    />
                  </Box>
                  {filteredStages.length > 0 ? (
                    filteredStages.map((stage, index) => (
                      <MenuItem
                        _hover={{
                          bg: "#D8000026",
                          textColor: "#D80000",
                        }}
                        key={index}
                        onClick={() => handleStageSelect(stage)}
                      >
                        {stage}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled>No matches found</MenuItem>
                  )}
                </MenuList>
              </Menu>
            </FormControl>

            <FormControl mb={2}>
              <FormLabel
                fontSize={"0.9375rem"}
                lineHeight={"1.47"}
                mb={-0.2}
                color={"#6f6b7d"}
              >
                Company Address:
              </FormLabel>
              <Input type="text" name="Company Address" required />
            </FormControl>

            <FormControl mb={2}>
              <FormLabel
                fontSize={"0.9375rem"}
                lineHeight={"1.47"}
                mb={-0.2}
                color={"#6f6b7d"}
              >
                City:
              </FormLabel>
              <Input type="text" name="City" required />
            </FormControl>

            <FormControl mb={2}>
              <FormLabel
                fontSize={"0.9375rem"}
                lineHeight={"1.47"}
                mb={-0.2}
                color={"#6f6b7d"}
              >
                State
              </FormLabel>
              <Input type="text" name="State" />
            </FormControl>

            <FormControl mb={2}>
              <FormLabel
                fontSize={"0.9375rem"}
                lineHeight={"1.47"}
                mb={-0.2}
                color={"#6f6b7d"}
              >
                Country:
              </FormLabel>
              <Select
                options={options}
                styles={customStyles}
                maxMenuHeight={"200px"}
                value={value}
                onChange={handleChange}
                placeholder="Select your country"
              />
            </FormControl>

            <FormControl mb={2}>
              <FormLabel
                fontSize={"0.9375rem"}
                lineHeight={"1.47"}
                mb={-0.2}
                color={"#6f6b7d"}
              >
                Zipcode:
              </FormLabel>
              <Input type="number" name="Zipcode" />
            </FormControl>

            <FormControl mb={2}>
              <FormLabel
                fontSize={"0.9375rem"}
                lineHeight={"1.47"}
                mb={-0.2}
                color={"#6f6b7d"}
              >
                Website:
              </FormLabel>
              <Input type="text" name="Website" />
            </FormControl>

            <FormControl mb={2}>
              <FormLabel
                fontSize={"0.9375rem"}
                lineHeight={"1.47"}
                mb={-0.2}
                color={"#6f6b7d"}
              >
                Number of Employees:
              </FormLabel>
              <Input type="number" name="Employees" />
            </FormControl>

            <FormControl mb={2}>
              <FormLabel
                fontSize={"0.9375rem"}
                lineHeight={"1.47"}
                mb={-0.2}
                color={"#6f6b7d"}
              >
                Nature of Business:
              </FormLabel>
              <Input type="text" name="nature of business" />
            </FormControl>

            <FormControl mb={2}>
              <FormLabel
                fontSize={"0.9375rem"}
                lineHeight={"1.47"}
                mb={-0.2}
                color={"#6f6b7d"}
              >
                Company Registration Type:
              </FormLabel>
              <Menu>
                <MenuButton
                  as={Button}
                  rightIcon={<IoChevronDownOutline />}
                  width="100%"
                  textAlign="left"
                  color={"#6f6b7d"}
                  background={"none"}
                  border={"1px solid #dbdade"}
                  _hover={{bg: "none"}}
                >
                  {selectedRegType ? selectedRegType : "Select Type"}
                </MenuButton>
                <MenuList maxHeight="200px" width={"280px"} overflowY="auto">
                  <Box p={2}>
                    <Input
                      placeholder="Reg Type..."
                      value={searchInput}
                      onChange={(e) => setSearchInput(e.target.value)}
                    />
                  </Box>
                  {filteredRegTypes.length > 0 ? (
                    filteredRegTypes.map((registrationType, index) => (
                      <MenuItem
                        _hover={{
                          bg: "#D8000026",
                          textColor: "#D80000",
                        }}
                        key={index}
                        onClick={() => handleRegType(registrationType)}
                      >
                        {registrationType}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled>No matches found</MenuItem>
                  )}
                </MenuList>
              </Menu>
            </FormControl>

            <FormControl mb={2}>
              <FormLabel
                fontSize={"0.9375rem"}
                lineHeight={"1.47"}
                mb={-0.2}
                color={"#6f6b7d"}
              >
                Standard:
              </FormLabel>
              <Input type="text" name="Standard" required />
            </FormControl>

            <FormControl mb={2}>
              <FormLabel
                fontSize={"0.9375rem"}
                lineHeight={"1.47"}
                mb={-0.2}
                color={"#6f6b7d"}
              >
                Service Type:
              </FormLabel>
              <Menu>
                <MenuButton
                  as={Button}
                  rightIcon={<IoChevronDownOutline />}
                  width="100%"
                  textAlign="left"
                  color={"#6f6b7d"}
                  background={"none"}
                  border={"1px solid #dbdade"}
                  _hover={{bg: "none"}}
                >
                  {selectedService ? selectedService : "Select Service"}
                </MenuButton>
                <MenuList maxHeight="200px" overflowY="auto">
                  <Box p={2}>
                    <Input
                      placeholder="Service..."
                      value={searchInput}
                      onChange={(e) => setSearchInput(e.target.value)}
                    />
                  </Box>
                  {filteredServices.length > 0 ? (
                    filteredServices.map((service, index) => (
                      <MenuItem
                        _hover={{
                          bg: "#D8000026",
                          textColor: "#D80000",
                        }}
                        key={index}
                        onClick={() => handleServiceSelect(service)}
                      >
                        {service}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled>No matches found</MenuItem>
                  )}
                </MenuList>
              </Menu>
            </FormControl>

            <FormControl mb={2}>
              <FormLabel
                fontSize={"0.9375rem"}
                lineHeight={"1.47"}
                mb={-0.2}
                color={"#6f6b7d"}
              >
                Status:
              </FormLabel>
              <Menu>
                <MenuButton
                  as={Button}
                  rightIcon={<IoChevronDownOutline />}
                  width="100%"
                  textAlign="left"
                  color={"#6f6b7d"}
                  background={"none"}
                  border={"1px solid #dbdade"}
                  _hover={{bg: "none"}}
                >
                  {selectedStatus ? selectedStatus : "Select Status"}
                </MenuButton>
                <MenuList maxHeight="200px" overflowY="auto">
                  <Box p={2}>
                    <Input
                      placeholder="Status..."
                      value={searchInput}
                      onChange={(e) => setSearchInput(e.target.value)}
                    />
                  </Box>
                  {filteredStatus.length > 0 ? (
                    filteredStatus.map((status, index) => (
                      <MenuItem
                        _hover={{
                          bg: "#D8000026",
                          textColor: "#D80000",
                        }}
                        key={index}
                        onClick={() => handleStatusSelect(status)}
                      >
                        {status}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled>No matches found</MenuItem>
                  )}
                </MenuList>
              </Menu>
            </FormControl>

            <FormControl mb={2}>
              <FormLabel
                fontSize={"0.9375rem"}
                lineHeight={"1.47"}
                mb={-0.2}
                color={"#6f6b7d"}
              >
                Source:
              </FormLabel>
              <Menu>
                <MenuButton
                  as={Button}
                  rightIcon={<IoChevronDownOutline />}
                  width="100%"
                  textAlign="left"
                  color={"#6f6b7d"}
                  background={"none"}
                  border={"1px solid #dbdade"}
                  _hover={{bg: "none"}}
                >
                  {selectedSource ? selectedSource : "Select Source"}
                </MenuButton>
                <MenuList maxHeight="200px" overflowY="auto">
                  <Box p={2}>
                    <Input
                      placeholder="Source..."
                      value={searchInput}
                      onChange={(e) => setSearchInput(e.target.value)}
                    />
                  </Box>
                  {filteredSource.length > 0 ? (
                    filteredSource.map((source, index) => (
                      <MenuItem
                        _hover={{
                          bg: "#D8000026",
                          textColor: "#D80000",
                        }}
                        key={index}
                        onClick={() => handleSourceSelect(source)}
                      >
                        {source}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled>No matches found</MenuItem>
                  )}
                </MenuList>
              </Menu>
            </FormControl>

            <FormControl mb={2}>
              <FormLabel
                fontSize={"0.9375rem"}
                lineHeight={"1.47"}
                mb={-0.2}
                color={"#6f6b7d"}
              >
                Executive:
              </FormLabel>
              <Menu>
                <MenuButton
                  as={Button}
                  rightIcon={<IoChevronDownOutline />}
                  width="100%"
                  textAlign="left"
                  color={"#6f6b7d"}
                  background={"none"}
                  border={"1px solid #dbdade"}
                  _hover={{bg: "none"}}
                >
                  {selectedExecutive ? selectedExecutive : "Select Executive"}
                </MenuButton>
                <MenuList maxHeight="200px" overflowY="auto">
                  <Box p={2}>
                    <Input
                      placeholder="Executive..."
                      value={searchInput}
                      onChange={(e) => setSearchInput(e.target.value)}
                    />
                  </Box>
                  {filteredExecutive.length > 0 ? (
                    filteredExecutive.map((executive, index) => (
                      <MenuItem
                        _hover={{
                          bg: "#D8000026",
                          textColor: "#D80000",
                        }}
                        key={index}
                        onClick={() => handleExecutiveSelect(executive)}
                      >
                        {executive}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled>No matches found</MenuItem>
                  )}
                </MenuList>
              </Menu>
            </FormControl>

            <FormControl mb={2}>
              <FormLabel
                fontSize={"0.9375rem"}
                lineHeight={"1.47"}
                mb={-0.2}
                color={"#6f6b7d"}
              >
                Follow up Date:
              </FormLabel>
              <Input type="date" name="follow up date" />
            </FormControl>

            <FormControl mb={2}>
              <FormLabel
                fontSize={"0.9375rem"}
                lineHeight={"1.47"}
                mb={-0.2}
                color={"#6f6b7d"}
              >
                Follow up Time:
              </FormLabel>
              <Input
                type="time"
                name="Follow up time"
                placeholder="Enter the Follow up Time"
              />
            </FormControl>
          </Grid>

          <Button
            background={"#D80000"}
            color={"white"}
            height={8}
            position="absolute"
            bottom={1}
            right={8}
          >
            Save Fresh
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default AddNewFresh;
