import {
  Box,
  Button,
  Flex,
  Heading,
  Text,
  Image,
  Tab,
  Tabs,
  Grid,
  TabList,
  TabPanel,
  TabPanels,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
} from "@chakra-ui/react";
import React, { useState } from "react";
import Navbar from "../Sidebar/Navbar";
import Profile from "../Images/UserImage.jpg";
import { TbAddressBook } from "react-icons/tb";
import { RiKey2Line } from "react-icons/ri";
import { FiFacebook } from "react-icons/fi";
import { PiLockOpen } from "react-icons/pi";
import Select from "react-select";
import countryList from "react-select-country-list";

const MyProfile = ({ isSidebarExpanded }) => {
  const options = countryList().getData();
  const width = isSidebarExpanded ? "calc(98vw - 270px)" : "calc(98vw - 110px)";
  const leftMargin = isSidebarExpanded ? "270px" : "110px";
  const [isChecked, setIsChecked] = useState(false);
  const handleCheckbox = () => {
    setIsChecked(!isChecked);
  };

  const [value, setValue] = React.useState(null);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isFocused ? "#D80000" : "black",
      backgroundColor: state.isFocused ? "#D8000026" : "white",
    }),
  };

  const handleChange = (value) => {
    setValue(value);
  };

  return (
    <Box>
      <Navbar isSidebarExpanded={isSidebarExpanded} currentPage="My Profile" />
      <Box
        position={"relative"}
        top={"100px"}
        left={leftMargin}
        width={width}
        height={"70px"}
        boxShadow="0 0 10px rgba(128, 128, 128, 0.5)"
        p={3}
        borderRadius={7}
        backgroundColor={"white"}
      >
        <Flex justifyContent={"flex-end"}>
          <Button color={"white"} background={"#D80000"} height={"30px"} mt={2}>
            SMTP Settings
          </Button>
        </Flex>
      </Box>

      <Box position={"relative"} top={"130px"} left={leftMargin} width={width}>
        <Flex justify={"space-between"}>
          <Box
            boxShadow="0 0 10px rgba(128, 128, 128, 0.5)"
            borderRadius={7}
            w={"33%"}
            height={"53vh"}
            p={4}
          >
            <Flex
              direction="column"
              align="center"
              justify="center"
              textAlign="center"
            >
              <Image
                src={Profile}
                height={"100px"}
                width={"100px"}
                borderRadius="full"
              />
              <Heading fontSize={"22px"} color={"#5D596C"} mb={2} mt={4}>
                Test
              </Heading>
              <Text
                color={"#a8aaae"}
                backgroundColor={"#f2f2f3"}
                borderRadius={4}
                px={2}
                py={1}
              >
                Admin
              </Text>
            </Flex>
            <Heading
              textAlign={"left"}
              fontSize={"13px"}
              fontFamily="Public Sans"
              color={"#a5a3ae !important"}
              mb={3}
            >
              DETAILS
            </Heading>
            <Text
              fontWeight={"bold"}
              fontFamily="Public Sans"
              color={"#151617"}
              textAlign={"left"}
              mb={3}
            >
              Email:{" "}
            </Text>
            <Text
              fontWeight={"bold"}
              fontFamily="Public Sans"
              color={"#151617"}
              textAlign={"left"}
              mb={3}
            >
              Phone No:{" "}
            </Text>
            <Text
              fontWeight={"bold"}
              fontFamily="Public Sans"
              color={"#151617"}
              textAlign={"left"}
              mb={8}
            >
              Reporting to:{" "}
            </Text>
          </Box>
          <Box
            boxShadow="0 0 10px rgba(128, 128, 128, 0.5)"
            borderRadius={7}
            w={"65%"}
            height={"auto"}
          >
            <Tabs>
              <TabList>
                <Grid templateColumns="repeat(4, 1fr)">
                  <Tab
                    color={"#5d595c"}
                    fontWeight={500}
                    lineHeight={1.375}
                    _hover={{ color: "#D80000" }}
                    _selected={{
                      color: "#D80000",
                      borderBottomColor: "#D80000",
                    }}
                  >
                    <Flex align="center">
                      <TbAddressBook size={25} />
                      <Text fontSize={"0.9375rem"}>Contact Information</Text>
                    </Flex>
                  </Tab>
                  <Tab
                    color={"#5d595c"}
                    fontWeight={500}
                    _hover={{ color: "#D80000" }}
                    _selected={{
                      color: "#D80000",
                      borderBottomColor: "#D80000",
                    }}
                  >
                    <Flex align="center">
                      <RiKey2Line size={25} />
                      <Text fontSize={"0.9375rem"}>Password Update</Text>
                    </Flex>
                  </Tab>
                  <Tab
                    color={"#5d595c"}
                    fontWeight={500}
                    _hover={{ color: "#D80000" }}
                    _selected={{
                      color: "#D80000",
                      borderBottomColor: "#D80000",
                    }}
                  >
                    <Flex align="center">
                      <FiFacebook size={25} />
                      <Text fontSize={"0.9375rem"}>Facebook Integration</Text>
                    </Flex>
                  </Tab>
                  <Tab
                    color={"#5d595c"}
                    fontWeight={500}
                    _hover={{ color: "#D80000" }}
                    _selected={{
                      color: "#D80000",
                      borderBottomColor: "#D80000",
                    }}
                  >
                    <Flex align="center">
                      <Text fontSize={"0.9375rem"}>Dashboard Settings</Text>
                    </Flex>
                  </Tab>
                  <Tab
                    color={"#5d595c"}
                    fontWeight={500}
                    _hover={{ color: "#D80000" }}
                    _selected={{
                      color: "#D80000",
                      borderBottomColor: "#D80000",
                    }}
                  >
                    <Flex align="center">
                      <PiLockOpen size={25} />
                      <Text fontSize={"0.9375rem"}>2 Step Authentication</Text>
                    </Flex>
                  </Tab>
                </Grid>
              </TabList>

              <TabPanels p={4}>
                {/* Contact Information TabPanel */}
                <TabPanel>
                  <Grid templateColumns="repeat(3, 1fr)" gap={4}>
                    <FormControl mb={2}>
                      <FormLabel pl={"10px"} mb={-0.2} color={"#6f6b7d"}>
                        Registered Email Address :
                      </FormLabel>
                      <Input type="email" name="Email" required />
                    </FormControl>
                    <FormControl mb={2}>
                      <FormLabel pl={"10px"} mb={-0.2} color={"#6f6b7d"}>
                        First Name :
                      </FormLabel>
                      <Input type="text" name="First Name" required />
                    </FormControl>
                    <FormControl mb={2}>
                      <FormLabel pl={"10px"} mb={-0.2} color={"#6f6b7d"}>
                        Last Name :
                      </FormLabel>
                      <Input type="text" name="Last Name" />
                    </FormControl>
                    <FormControl mb={2}>
                      <FormLabel pl={"10px"} mb={-0.2} color={"#6f6b7d"}>
                        Contact Number :
                      </FormLabel>
                      <Input type="telephone" name="Contact" />
                    </FormControl>
                    <FormControl mb={2}>
                      <FormLabel pl={"10px"} mb={-0.2} color={"#6f6b7d"}>
                        Alternate Contact Number
                      </FormLabel>
                      <Input
                        type="telephone"
                        name="Contact"
                        placeholder="Enter your Alternate Contact"
                      />
                    </FormControl>
                    <FormControl mb={2}>
                      <FormLabel pl={"10px"} mb={-0.2} color={"#6f6b7d"}>
                        City :
                      </FormLabel>
                      <Input type="text" name="City" />
                    </FormControl>
                    <FormControl mb={2}>
                      <FormLabel pl={"10px"} mb={-0.2} color={"#6f6b7d"}>
                        State :
                      </FormLabel>
                      <Input type="text" name="State" />
                    </FormControl>

                    <FormControl mb={2}>
                      <FormLabel
                        fontSize={"0.9375rem"}
                        lineHeight={"1.47"}
                        mb={-0.1}
                        color={"#6f6b7d"}
                      >
                        Country:
                      </FormLabel>
                      <Select
                        options={options}
                        styles={customStyles}
                        maxMenuHeight={"200px"}
                        value={value}
                        onChange={handleChange}
                        placeholder="Select your country"
                      />
                    </FormControl>

                    <FormControl mb={2}>
                      <FormLabel pl={"10px"} mb={-0.2} color={"#6f6b7d"}>
                        Upload Profile Picture :
                      </FormLabel>
                      <Input
                        type="file"
                        accept=".jpg, .jpeg, .webp"
                        name="Profile"
                      />
                    </FormControl>

                    <FormControl mb={2}>
                      <FormLabel pl={"10px"} mb={-0.2} color={"#6f6b7d"}>
                        Profile Picture :
                      </FormLabel>
                      <Image
                        src={Profile}
                        height={"100px"}
                        width={"100px"}
                        borderRadius="full"
                      />
                    </FormControl>

                    <Flex flexDirection={"row"}>
                      <Checkbox alignItems={"top"} mt={1} mr={2} />
                      <Text>Disable Guide</Text>
                    </Flex>
                  </Grid>
                  <Flex justifyContent={"flex-start"}>
                    <Button
                      color={"white"}
                      background={"#D80000"}
                      height={"30px"}
                      mt={2}
                    >
                      Update
                    </Button>
                  </Flex>
                </TabPanel>

                {/* Password Update TabPanel */}
                <TabPanel>
                  <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                    <FormControl mb={2} gridColumn="span 1">
                      <FormLabel pl={"10px"} mb={-0.2} color={"#6f6b7d"}>
                        Old Password :
                      </FormLabel>
                      <Input type="password" name="OldPassword" />
                    </FormControl>
                    <Box />
                  </Grid>

                  <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                    <FormControl mb={2}>
                      <FormLabel pl={"10px"} mb={-0.2} color={"#6f6b7d"}>
                        New Password :
                      </FormLabel>
                      <Input type="password" name="NewPassword" />
                    </FormControl>
                    <FormControl mb={2}>
                      <FormLabel pl={"10px"} mb={-0.2} color={"#6f6b7d"}>
                        Confirm New Password :
                      </FormLabel>
                      <Input type="password" name="ConfirmPassword" />
                    </FormControl>
                  </Grid>
                  <Flex justifyContent={"flex-start"}>
                    <Button
                      color={"white"}
                      background={"#D80000"}
                      height={"30px"}
                      mt={2}
                    >
                      Update Password
                    </Button>
                  </Flex>
                </TabPanel>

                {/* Add TabPanels for other tabs */}
                <TabPanel>
                  <Text textAlign={"left"}>
                    The content will be visible in this tab only if admin
                    provides you with the permission to manage your Company
                    Facebook Pages
                  </Text>
                  <br />
                  <Text textAlign={"left"}>
                    Admin can manage the Facebook pages under Module Settings --
                    Integrations
                  </Text>
                </TabPanel>

                <TabPanel>
                  <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                    <FormControl mb={2}>
                      <FormLabel mb={-0.2} color={"#6f6b7d"}>
                        Default Dashboard :
                      </FormLabel>
                      <Input type="text" name="Dashboard" />
                    </FormControl>
                    <Box />
                  </Grid>
                  <Flex justifyContent={"flex-start"}>
                    <Button
                      color={"white"}
                      background={"#D80000"}
                      height={"30px"}
                      mt={2}
                    >
                      Save
                    </Button>
                  </Flex>
                </TabPanel>

                <TabPanel>
                  <Text textAlign={"left"} mb={6}>
                    Authentication Type
                  </Text>
                  <Flex flexDirection={"row"} alignItems="center">
                    <Checkbox
                      alignItems={"top"}
                      mt={1}
                      mr={2}
                      onChange={handleCheckbox}
                    />
                    <Text>Email OTP</Text>
                    {isChecked && (
                      <FormControl ml={4} width="auto">
                        <FormLabel mb={-0.2} color={"#6f6b7d"}>
                          Email Verification OTP
                        </FormLabel>
                        <Input type="text" placeholder="Enter OTP" />
                      </FormControl>
                    )}
                  </Flex>
                  <Flex justifyContent={"flex-end"}>
                    <Button
                      color={"white"}
                      background={"#D80000"}
                      height={"30px"}
                      mt={2}
                    >
                      Save
                    </Button>
                  </Flex>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};

export default MyProfile;
