import { Box } from '@chakra-ui/react'
import React from 'react'

const Finance = () => {
  return (
    <Box>
      
    </Box>
  )
}

export default Finance
