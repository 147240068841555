import { Box, Checkbox, Flex, Heading, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";
import React from "react";
import Navbar from "./Sidebar/Navbar";
import { Navigate, useNavigate } from "react-router-dom";

const Fresh = ({ isSidebarExpanded }) => {
  const width = isSidebarExpanded ? "calc(98vw - 270px)" : "calc(98vw - 110px)";
  const leftMargin = isSidebarExpanded ? "270px" : "110px";
  const navigate = useNavigate();

  const handleOnClick = (e) => {
    e.preventDefault();
    navigate("/addnewfresh");
  };


  return (
    <Box>
      <Navbar isSidebarExpanded={isSidebarExpanded} currentPage="Fresh" />
      <Box
        position="absolute"
        top={1}
        left={leftMargin}
        transition="left 0.2s ease, width 0.2s ease"
        width={width}
      >
        <Box
          boxShadow="0 0 10px rgba(128, 128, 128, 0.5)"
          // p={3}
          borderRadius={7}
          position={"relative"}
          top={100}
        >
          <Flex p={3} mb={4}>
            <Heading color={"#151617"} as="h5" fontSize={"18px"} fontWeight={"bold"} fontFamily={"Public Sans, sans-serif"}>1 record found</Heading>
            <Box position={"absolute"} right={10}>
              <Flex
                fontSize={"14px"}
                border={"1px solid red"}
                lineHeight={7}
                borderRadius={8}
                fontFamily={"Public Sans, sans-serif"}
                color={"#D80000"}
              >
                <Text borderRight={"1px solid #D80000"} pl={4} pr={4} cursor={"pointer"} _hover={{bg: "#D8000026",}}>
                  X
                </Text>
                <Text borderRight={"1px solid #D80000"} pl={4} pr={4} cursor={"pointer"} _hover={{bg: "#D8000026",}}>
                  Filter
                </Text>
                <Text borderRight={"1px solid #D80000"} pl={4} pr={4} cursor={"pointer"} _hover={{bg: "#D8000026",}}>
                  Bulk Upload
                </Text>
                <Text pl={4} pr={4} cursor={"pointer"} _hover={{bg: "#D8000026",}} onClick={handleOnClick}>Add New</Text>
              </Flex>
            </Box>
          </Flex>
          <TableContainer>
            <Table>
              <Thead>
                <Tr backgroundColor={"#4b4b4b"}>
                  <Th><Checkbox /></Th>
                  <Th color={"white"} pt={"0.88rem"} pb={"0.88rem"} >SL No.</Th>
                  <Th color={"white"}>ID</Th>
                  <Th color={"white"}>Name</Th>  
                  <Th color={"white"}>Email Address</Th>
                  <Th color={"white"}>Phone Number</Th>
                  <Th color={"white"}>Executive</Th>
                  <Th color={"white"}>Alternate Contact</Th>
                  <Th color={"white"}>Designation</Th>
                  <Th color={"white"}>Profile Picture</Th>
                  <Th color={"white"}>Address</Th>
                  <Th color={"white"}>Country</Th>
                  <Th color={"white"}>State</Th>
                  <Th color={"white"}>Date</Th>
                  <Th color={"white"}>status</Th>
                  <Th color={"white"}>Created On</Th>
                  <Th color={"white"}>Total no. Employees</Th>
                  <Th color={"white"}>Action</Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td><Checkbox /></Td>
                  <Td>1</Td>
                  <Td>C26</Td>
                  <Td>test</Td>
                  <Td>test@example.com</Td>
                  <Td>1234567890</Td>
                  <Td></Td>
                  <Td></Td>
                  <Td></Td>
                  <Td></Td>
                  <Td></Td>
                  <Td>IND</Td>
                  <Td></Td>
                  <Td>Jan 27, 2024</Td>
                  <Td>New</Td>
                  <Td>Jan 29, 2024 05:09 PM</Td>
                  <Td></Td>
                  <Td></Td>
                </Tr>
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Box>
  );
};

export default Fresh;
